import React from 'react';
import {t} from '../../common/translationUtils';
import {withStyles} from '@material-ui/core/styles';
import tableStyles from '../../common/styles/tableStyles';
import PeasButton from '../../common/components/forms/PeasButton';

const PopupButton = props => {
  const {onClick, classes} = props;
  return <PeasButton
    color={null}
    className={classes.cellButton}
    variant="outlined"
    label={t('common.button.show')}
    onClick={onClick}/>
}

export default withStyles(tableStyles)(PopupButton);