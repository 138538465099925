import React from 'react';
import {t} from '../../common/translationUtils';
import {Repeat, List} from '@material-ui/icons';
import {Tooltip, IconButton} from '@material-ui/core';
import {TABLE_ACTION} from '../selectors';

const TITLES = {
  [TABLE_ACTION.SHOW_ITEMS]: 'operations.tooltip.showItems',
  [TABLE_ACTION.SHOW_HISTORY]: 'operations.tooltip.showHistory',
  [TABLE_ACTION.RESTART]: 'operations.tooltip.restart'
};

const ICONS = {
  [TABLE_ACTION.SHOW_ITEMS]: <List fontSize="small"/>,
  [TABLE_ACTION.SHOW_HISTORY]: <List fontSize="small"/>,
  [TABLE_ACTION.RESTART]: <Repeat fontSize="small"/>
};

const ImportKrediActionButton = props => {
  const {action, type} = props;
  const title = TITLES[type] ? t(TITLES[type]) : '';
  const icon = ICONS[type] ? ICONS[type] : null;

  return (
    <Tooltip title={title}>
      <IconButton aria-label="open" onClick={action}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};

export default ImportKrediActionButton;