import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PeasButton from '../common/components/forms/PeasButton';
import {t} from '../common/translationUtils';
import {routes} from '../routes';
import styles from '../common/styles/dashboardStyles';

class InterfaceServicesDashboard extends React.Component {

  route = path => () => {
    this.props.history.push(path);
  }

  render() {
    const {classes} = this.props;

    const sectionButton = (label, handleClick) => (
      <PeasButton
        color={null}
        className={classes.sectionButton}
        variant="outlined"
        label={label}
        onClick={handleClick}
        disabled={handleClick === undefined}/>
    );

    return (
      <Grid container spacing={24}>
        <Grid item xs={12} md={3}>
          <Typography variant="h5" className={classes.subtitle}>
          {t('common.interface.services')}
          </Typography>
          {sectionButton(t('common.manual.import'), this.route(routes.manualImports.base))}
          {sectionButton(t('common.booking.voucher'), this.route(routes.bookingVouchers.base))}
        </Grid>
      </Grid>
    );
  }
};

export default withStyles(styles)(InterfaceServicesDashboard);
