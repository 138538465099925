import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {t} from '../../common/translationUtils';
import {routes} from '../../routes';
import {withStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SectionTitle from '../../common/components/layout/SectionTitle';
import PeasLoading from '../../common/components/forms/PeasLoading';
import PeasAlert from '../../common/components/forms/PeasAlert';
import * as actions from './actions';
import {getFormButtons, getEmptyActiveResource} from  '../../common/formUtils';
import formStyles from '../../common/styles/formStyles';
import SiteBaseData from './SiteBaseData';
import SiteSubcategories from './SiteSubcategories';

const SiteForm = props => {
  const [resource, setResource] = useState(getEmptyActiveResource(props));
  useEffect(init, []);
  useEffect(fillForm, [props.site]);

  function init() {
    const {match} = props;
    props.actions.loadResources(match.params.id);
    props.actions.changeTab(0);
  };

  function fillForm() {
    setResource(props.site ? props.site : getEmptyActiveResource(props));
  };

  const handleClose = withSuccess => {
    const route = withSuccess === true ? `${routes.sites.base}?success=true` : routes.sites.base;
    props.history.push(route);
  };

  const handleChange = field => event => {
    setResource({...resource, [field]: event.target.value});
  };

  const handleCustomerChange = field => event => {
    setResource({...resource, [field]: {id: event.target.value}});
  };

  const handleActiveChange = event => {
    setResource({...resource, active: event.target.checked});
  };

  const handleChangeSubcategories = subcategories => {
    setResource({...resource, subcategories});
  };

  const handleTabChange = (event, tab) => {
    props.actions.changeTab(tab);
  };

  const handleDelete = () => {
    props.actions.remove(props.match.params.id, () => {
      handleClose(true);
    });
  };

  const handleSubmit = () => {
    props.actions.save(resource, props.match.params.id, () => {
      handleClose(true);
    });
  };

  const {classes, match, customers, subcategories, categories, tab, loading, errors} = props;
  const title = match.params.id ? t('site.title.edit') : t('site.title.create');
  const deleteFunc = match.params.id ? handleDelete : null;
  const buttons = getFormButtons(handleClose, deleteFunc, handleSubmit);

  return loading ? <PeasLoading/> : (
    <div>
      <SectionTitle label={title} buttons={buttons}/>
      <form>
        <PeasAlert
          multiple
          variant="error"
          open={errors !== null && errors.length > 0}
          onClose={props.actions.resetError}
          messages={errors}
        />
        <Tabs value={tab} onChange={handleTabChange} textColor="primary" indicatorColor="primary" className={classes.tabs}>
          <Tab label={t('site.baseData')}/>
          <Tab label={t('common.subcategories')}/>
        </Tabs>
        <SiteBaseData
          visible={tab === 0}
          resource={resource}
          customers={customers}
          formClass={classes.form}
          handleChange={handleChange}
          handleCustomerChange={handleCustomerChange}
          handleActiveChange={handleActiveChange}/>
        <SiteSubcategories
          visible={tab === 1}
          subcategories={subcategories}
          categories={categories}
          resource={resource}
          onChange={handleChangeSubcategories}/>
      </form>
    </div>
  );
};

function mapStateToProps(state) {
  const {loading, site, subcategories, categories, customers, tab, errors} = state.siteForm;
  return {
    loading, site, subcategories, categories, customers, tab, errors
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(formStyles)(SiteForm));