import * as actionNames from './actionNames';
import axios from 'axios';
import {handleError} from '../common/serverUtils';
import {CUSTOMERS_ENDPOINT, USERS_ENDPOINT} from '../endpoints';
import {getFilters, getEqFilter} from '../common/filterUtils';

const filters = active => [
  active === 'all' ? '' : getEqFilter('active', active)
];

async function loadCustomers(id, activeOption, dispatch) {
  dispatch({type: actionNames.LOADING});
  const baseLink = id ? `${USERS_ENDPOINT}/${id}/customers` : CUSTOMERS_ENDPOINT;
  const filterQuery = getFilters(filters(activeOption));
  try {
    const response = await axios.get(`${baseLink}${filterQuery}`);
    const {_embedded} = response.data;
    dispatch({type: actionNames.LOADED, customers: _embedded ? _embedded.customerResourceList : []});
  } catch (error) {
    handleError(error, dispatch);
  };
}

export const loadAllCustomers = activeOption => {
  return dispatch => {
    loadCustomers(null, activeOption, dispatch);
  }
};

export const loadUserCustomers = (id, activeOption) => {
  return async dispatch => {
    loadCustomers(id, activeOption, dispatch);
  };
};

export const changeActiveOption = activeOption => dispatch => {
  dispatch({type: actionNames.CHANGE_ACTIVE_OPTION, activeOption});
};
