import * as actionNames from './actionNames';

const initialState = {
  loading: false,
  sites: null,
  activeOption: true
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case actionNames.LOADING:
    return {
      ...state,
      loading: true
    };
  case actionNames.LOADED:
    return {
      ...state,
      loading: false,
      sites: action.sites
    };
  case actionNames.CHANGE_ACTIVE_OPTION:
    return {
      ...state,
      activeOption: action.activeOption
    };
  default:
    return state
  }
};
