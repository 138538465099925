import * as actionNames from './actionNames';
import axios from 'axios';
import * as loginUtils from '../common/loginUtils';
import {USERS_ENDPOINT} from '../endpoints';
import {validate} from './validations';

export const save = resource => {
  const id = loginUtils.getUser().userId;
  const route = `${USERS_ENDPOINT}/${id}/password`;
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    const {valid, errors} = validate(resource);
    if (!valid) {
      return dispatch({type: actionNames.ERROR, errors});
    }
    try {
      await axios.post(route, resource);
      dispatch({type: actionNames.SAVED});
    } catch (error) {
      dispatch({
        type: actionNames.ERROR,
        errors: [error.message]
      });
    };
  };
};

export const resetError = () => {
  return dispatch => {
    dispatch({type: actionNames.RESET_ERROR});
  };
};
