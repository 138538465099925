import * as actionNames from './actionNames';
import axios from 'axios';
import {handleError} from '../common/serverUtils';
import {USERS_ENDPOINT, CUSTOMERS_ENDPOINT} from '../endpoints';

export const loadComments = customerId => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    try {
      const commentsRoute = `${CUSTOMERS_ENDPOINT}/${customerId}/comments`;
      const usersResponse = await axios.get(USERS_ENDPOINT);
      const customerResponse = await axios.get(`${CUSTOMERS_ENDPOINT}/${customerId}`);
      const commentsResponse = await axios.get(commentsRoute);
      const {_embedded: usersData} = usersResponse.data;
      const {_embedded: commentsData} = commentsResponse.data;
      dispatch({
        type: actionNames.LOADED,
        customer: customerResponse.data,
        comments: commentsData ? commentsData.commentResourceList : [],
        users: usersData ? usersData.userList : []
      });
    } catch (error) {
      handleError(error, dispatch);
    };
  };
};
