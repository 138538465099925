import * as actionNames from './actionNames';
import axios from 'axios';
import * as serverUtils from '../../common/serverUtils';
import * as loginUtils from '../../common/loginUtils';
import {COMMENTS_ENDPOINT} from '../../endpoints';
import {validate} from './validations';

export const loadResources = id => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    try {
      const commentResource = id ? await axios.get(`${COMMENTS_ENDPOINT}/${id}`) : null;
      dispatch({
        type: actionNames.LOADED_RESOURCES,
        comment: commentResource ? commentResource.data : null
      });
    } catch (error) {
      serverUtils.handleError(error, dispatch);
    };
  };
};

export const save = (resource, customerId, commentId, redirectCallback) => {
  const route = commentId ? `${COMMENTS_ENDPOINT}/${commentId}` : COMMENTS_ENDPOINT;
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    const {valid, errors} = validate(resource);
    if (!valid) {
      return dispatch({type: actionNames.ERROR, errors});
    }
    try {
      await axios.post(route, {
        ...resource,
        userId: loginUtils.getUser().userId,
        customerId: Number(customerId)
      });
      redirectCallback();
      dispatch({type: actionNames.SAVED});
    } catch (error) {
      dispatch({
        type: actionNames.ERROR,
        errors: [error.message]
      });
    };
  };
};

export const remove = (id, redirectCallback) => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    try {
      await axios.delete(`${COMMENTS_ENDPOINT}/${id}`);
      redirectCallback();
      dispatch({type: actionNames.REMOVED});
    } catch (error) {
      dispatch({
        type: actionNames.ERROR,
        errors: [error.message]
      });
    };
  };
};

export const resetError = () => {
  return dispatch => {
    dispatch({type: actionNames.RESET_ERROR});
  };
};
