import React from 'react';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PeasDatePicker from '../../common/components/forms/PeasDatePicker';
import PeasTextField from '../../common/components/forms/PeasTextField';

const SubcategoryMargeRow = props => {
  const {marge, classes} = props;

  return (
    <TableRow key={props.key}>
      <TableCell component="th" scope="row">
        <PeasDatePicker
          value={marge.validFrom || null}
          onChange={props.handleChange('validFrom')}/>
      </TableCell>
      <TableCell>
        <PeasTextField
          value={marge.marge || ''}
          onChange={props.handleChange('marge')}/>
      </TableCell>
      <TableCell className={classes.tableActions}>
        {props.type === 'EDIT' ?
          <IconButton aria-label="remove" onClick={props.handleRemove}>
            <Remove fontSize="small"/>
          </IconButton> :
          <IconButton
            aria-label="add"
            onClick={props.handleAdd}
            disabled={!marge.validFrom || !marge.marge}>
            <Add fontSize="small"/>
          </IconButton>
        }
      </TableCell>
    </TableRow>
  );
};

export default SubcategoryMargeRow;