export const getUser = () => {
  const item = localStorage.getItem('user');
  return item ? JSON.parse(item) : null;
};

export const rememberUser = user => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const forgetUser = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('language');
};

export const roles = {
  USER: 'USER',
  ADMIN: 'ADMIN',
  REPORTS: 'REPORTS'
};

/* 
  Roles are sorted according to its importance.
  It's vital because if user has role ADMIN and REPORTS his main
  role will be ADMIN and it will determine his default login route.
  If you add minor roles, please add them at the end of the array.
*/
export const getRolesAsArray = () => {
  return [roles.USER, roles.ADMIN, roles.REPORTS];
};

export const getMainRole = userRoles => {
  return getRolesAsArray().find(role => userRoles.includes(role));
};
