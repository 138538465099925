import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {t} from '../../translationUtils';
import PeasTextField from '../forms/PeasTextField';

const styles = {
  peasSearch: {
    maxWidth: '250px'
  }
};

const PeasSearch = props => {
  const {id, value, handleChange, placeholder, classes} = props;
  return (
    <PeasTextField
      className={classes.peasSearch}
      id={id || 'peas-search'}
      placeholder={placeholder || t('common.search')}
      value={value || ''}
      onChange={handleChange}/>
  );
};

export default withStyles(styles)(PeasSearch);