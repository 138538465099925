import React from 'react';
import {t} from '../../common/translationUtils';
import {withStyles} from '@material-ui/core/styles';
import tableStyles from '../../common/styles/tableStyles';
import PeasButton from '../../common/components/forms/PeasButton';

const RequestPayloadButton = props => {
  const {payload, setPayload, classes} = props;
  return (payload ? <PeasButton
    color={null}
    className={classes.cellButton}
    variant="outlined"
    label={t('common.button.show')}
    onClick={() => setPayload(payload)}/> : null);
}

export default withStyles(tableStyles)(RequestPayloadButton);