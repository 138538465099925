import {sortByName} from '../common/sortUtils';

export const sortByCustomer = (a, b) => {
  const aCustomer = a.customer ? a.customer.name : null;
  const bCustomer = b.customer ? b.customer.name : null;
  if (aCustomer === null) return 1;
  if (bCustomer === null) return -1;
  if (aCustomer.toLowerCase() < bCustomer.toLowerCase()) { return -1; }
  if (aCustomer.toLowerCase() > bCustomer.toLowerCase()) { return 1; }
  return sortByName(a, b);
};
