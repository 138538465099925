import {routes} from '../routes';
import {roles} from '../common/loginUtils';

const viewsWithoutMenuBar = [routes.login.base];
export const tabs = [
  // REPORTS
  {
    roles: [roles.USER, roles.ADMIN],
    route: routes.reports.base,
    label: 'app.reports',
    internalRoutes: [routes.comments.base, routes.comments.form]
  },
  // ADMINISTRATION
  {
    roles: [roles.ADMIN],
    route: routes.adminDashboard.base,
    label: 'app.administration',
    internalRoutes: [
      routes.users.base, routes.users.form,
      routes.customers.base, routes.customers.form,
      routes.sites.base, routes.sites.form,
      routes.subcategories.base, routes.subcategories.form,
      routes.products.base, routes.products.form,
      routes.categories.base, routes.categories.form,
      routes.reasonCodes.base, routes.reasonCodes.form,
      routes.importPOSData.base,
      routes.importCreditCardData.base,
      routes.importKrediData.base
    ]
  },
  // MONTHLY REPORTS
  {
    roles: [roles.REPORTS],
    route: routes.monthlyReports.base,
    label: 'app.monthlyReports',
    internalRoutes: []
  },
  {
    roles: [roles.REPORTS],
    route: routes.interfaceServices.base,
    label: 'app.interfaceServices',
    internalRoutes: [
      routes.manualImports.base
    ]
  }
];

export const roleDefaultRoutes = {
  USER: routes.reports.base,
  ADMIN: routes.adminDashboard.base,
  REPORTS: routes.monthlyReports.base
};

export const shouldHideMenu = route => {
  return viewsWithoutMenuBar.includes(route);
};

export const shouldShowTab = (tab, user) => {
  if (!user) {
    return false;
  }
  return tab.roles.find(role => {
    return user.roles && user.roles.length > 0 ? user.roles.includes(role) : false;
  });
}

const getTabValueByInternal = route => {
  return tabs.findIndex(tab => {
    return tab.internalRoutes.find(internal => route.includes(internal));
  });
}

export const getTabValue = route => {
  const mainRouteValue = tabs.map(tab => tab.route).indexOf(route);
  return mainRouteValue > -1 ? mainRouteValue : getTabValueByInternal(route);
};
