import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import {parse, format, subMonths, addMonths} from 'date-fns/esm';

const PeasMonthPicker = props => {
  const pickerConfig = {
    initialDay: 1,
    startMonth: subMonths(new Date(), 6),
    endMonth: addMonths(new Date(), 8)
  };

  const formatDate = date => {
    const today = new Date();
    date = date || new Date(today.getFullYear(), today.getMonth(), pickerConfig.initialDay);
    return format(date, 'yyyy-MM-dd');
  };

  const options = [];
  for(let day = pickerConfig.startMonth; day <= pickerConfig.endMonth; day = addMonths(day, 1)) {
    const date = new Date(day.getFullYear(), day.getMonth(), pickerConfig.initialDay);
    options.push({value: formatDate(date)});
  }

  return (
    <FormControl fullWidth margin="normal" className={props.className}>
      <InputLabel htmlFor={`${props.name}-simple`} required={props.required}>
        {props.label}
      </InputLabel>
      <Select
        fullWidth
        value={props.value}
        onChange={props.onChange}
        inputProps={{
          name: props.name,
          id: `${props.name}-simple`,
        }}
      >
        {options ? options.map(option => {
          const dateValue = parse(option.value, 'yyyy-MM-dd', new Date());
          const month = format(dateValue, 'MMMM yyyy');
          return (<MenuItem key={month} value={option.value}>
            {month}
          </MenuItem>);
        }) : null}
      </Select>
    </FormControl>
  );
};

export default PeasMonthPicker;