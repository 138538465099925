import React, {useState} from 'react';
import {useList} from '../common/hooks/useList';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {t} from '../common/translationUtils';
import {routes} from '../routes';
import {withStyles} from '@material-ui/core/styles';
import {TableBody, TableHead, TableRow} from '@material-ui/core';
import PeasTable from '../common/components/list/PeasTable';
import PeasTableCell from '../common/components/list/PeasTableCell';
import SectionTitle from '../common/components/layout/SectionTitle';
import * as listUtils from '../common/listUtils';
import * as actions from './actions';
import tableStyles from '../common/styles/tableStyles';
import posStyles from './styles';
import PayloadModal from './components/PayloadModal';
import ImportCreditCardActionButton from './components/ImportCreditCardActionButton';
import ItemStatusIndicators from './components/ItemStatusIndicators';
import {formatRequestDate, TABLE_ACTION} from './selectors';
import RestartItemAlert from "./components/RestartItemAlert";

const ImportCreditCardData = props => {
  const {loading, operations, paginationConfig, history, actions, classes, triggerResponse} = props;
  const [payload, setPayload] = useState(false);
  const [filterer, pagination] = useList({
    loader: actions.loadOperations,
    defaultFilters: {siteName: '', siteid: '', status: 'ALL'},
    paginationConfig,
    history
  });

  const handleTrigger = requestId => () => {
    actions.restartRequest(requestId, -1, () => {
      actions.loadOperations(filterer.filters, pagination.page);
    });
  };

  const redirectToItem = requestId => {
    history.push(routes.importCreditCardData.items(requestId));
  };

  return (
    <div>
      <SectionTitle
        label={t('common.importCreditCard')}
        buttons={listUtils.getListButtons({
          backRoute: routes.adminDashboard.base
        }, props)}/>
      <RestartItemAlert response={triggerResponse} actions={actions}/>
      <PeasTable
        className={classes.tableContainer}
        list={operations}
        pagination={pagination}
        loading={loading}
        emptyText={t('operations.message.empty')}>
        <TableHead>
          <TableRow>
            <PeasTableCell header label={t('common.id')}/>
            {/*<PeasTableCell header label={t('site.label.siteId')}/>*/}
            {/*<PeasTableCell header label={t('operations.label.siteName')}/>*/}
            <PeasTableCell header label={t('operations.label.businessDayDate')}/>
            <PeasTableCell header label={t('importKredi.column.filename')}/>
            <PeasTableCell header label={t('common.time')}/>
            {/*<PeasTableCell header label={t('operations.label.requestPayload')}/>*/}
            <PeasTableCell header label={t('common.items')}/>
            <PeasTableCell header/>
          </TableRow>
        </TableHead>
        <TableBody>
          {operations ? operations.map(operation => (
            <TableRow key={operation.requestId}>
              <PeasTableCell component="th" scope="row" value={operation.requestId}/>
              {/*<PeasTableCell value={operation.siteId}/>*/}
              <PeasTableCell value={operation.businessDate}/>
              <PeasTableCell value={operation.name}/>
              <PeasTableCell value={formatRequestDate(operation.requestTime)}/>
              {/*<PeasTableCell width="100px">*/}
              {/*  <RequestPayloadButton setPayload={setPayload} payload={operation.requestPayload}/>*/}
              {/*</PeasTableCell>*/}
              <PeasTableCell width="100px">
                <ItemStatusIndicators operation={operation} classes={classes}/>
                </PeasTableCell>
              <PeasTableCell actions>
                { operation.itemsCount === -1 ?
                <ImportCreditCardActionButton
                  type={TABLE_ACTION.RESTART}
                  action={handleTrigger(operation.requestId)}/> : null
                }
                <ImportCreditCardActionButton
                  type={TABLE_ACTION.SHOW_ITEMS}
                  action={() => redirectToItem(operation.requestId)}/>
              </PeasTableCell>
            </TableRow>
          )) : null}
        </TableBody>
      </PeasTable>
      <PayloadModal
        open={Boolean(payload)}
        payload={payload}
        handleClose={() => setPayload(false)}/>
    </div>
  );
};

function mapStateToProps(state) {
  const {loading, triggerResponse, operations, paginationConfig} = state.importCreditCardData;
  return {loading, triggerResponse, operations, paginationConfig};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles({
  ...tableStyles,
  ...posStyles
})(ImportCreditCardData));