import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {routes} from '../../routes';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import SectionTitle from '../../common/components/layout/SectionTitle';
import PeasLoading from '../../common/components/forms/PeasLoading';
import PeasAlert from '../../common/components/forms/PeasAlert';
import PeasTextField from '../../common/components/forms/PeasTextField';
import PeasSelect from '../../common/components/forms/PeasSelect';
import * as actions from './actions';
import {getEmptyActiveResource, getFormButtons} from '../../common/formUtils';
import formStyles from '../../common/styles/formStyles';
import TableBody from "@material-ui/core/TableBody";
import {sortByName, sortByNumber} from "../../common/sortUtils";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import PeasDatePicker from "../../common/components/forms/PeasDatePicker";
import {countDecimals} from '../../common/numberUtils';
import {getResourceToSave} from './selectors';

const ManualImportForm = props => {
  const [resource, setResource] = useState(getEmptyActiveResource(props));
  useEffect(init, []);
  useEffect(fillForm, [props.reasonCode]);
  useEffect(loadAll, []);

  function init() {
    const {id} = props.match.params;
    props.actions.loadResources(id);
  };

  function fillForm() {
    setResource(props.reasonCode ? props.reasonCode : getEmptyActiveResource(props));
  };

  function loadAll() {
    props.actions.loadAll();
  }

  const handleClose = withSuccess => {
    const route = withSuccess === true ?
      `${routes.manualImports.base}?success=true` :
      routes.manualImports.base;
    props.history.push(route);
  };

  const handleExecute = id => {
    props.actions.process(props.match.params.id, () => {
      handleClose(true);
    });
  };

  const handleChange = field => value => {
    console.log('field ' + field);
    setResource({...resource, [field]: value});
  };

  const convertToDecimal = (field, id, value) => {
    if (countDecimals(value) > 2) {
      return getArrayValue(field, id);
    }
    return value;
  };

  const handleArrayChange = (field, id, account, name) => event => {
    const {value} = event.target;
    setResource({
      ...resource,
      [field]: {
        ...resource[field],
        [id]: {
          value: value && !isNaN(value) ? convertToDecimal(field, id, value) : value,
          account,
          name
        }
      }
    });
    return '';
  };

  const getArrayValue = (field, id) => {
    if (resource[field] && resource[field][id]) {
      return resource[field][id].value || '';
    }
    return '';
  };

  const checksum = () => {
    let checksum = 0;
    let subcategories = resource["subcategories"];
    let pays = resource["pays"];
    let cards = resource["cards"];
    if (subcategories !== null && subcategories !== undefined) {
      for (let propertyName in subcategories) {
        if (subcategories[propertyName] && subcategories[propertyName].value) {
          checksum += Number(subcategories[propertyName].value);
        }
      }
    }
    if (subcategories !== null && subcategories !== undefined) {
      for (let propertyName in pays) {
        if (pays[propertyName].account === "0") {
          // do nothing
        } else if ('Kassen Ist' === pays[propertyName].name) {
          checksum -= Number(pays[propertyName].value);
        } else if (pays[propertyName].value) {
          checksum += Number(pays[propertyName].value);
        }
      }
    }
    if (subcategories !== null && subcategories !== undefined) {
      for (let propertyName in cards) {
        if (cards[propertyName].account === "0") {
          // do nothing
        } else if ('Kassen Ist' === cards[propertyName].name) {
          checksum -= Number(cards[propertyName].value);
        } else if (cards[propertyName].value) {
          checksum -= Number(cards[propertyName].value);
        }
      }
    }
    return Math.round(checksum * 1000) / 1000;
  };

  const handleSelectChange = field => event => {
    const {value} = event.target;
    setResource({...resource, [field]: value});
  };
  
  const handleDelete = () => {
    props.actions.remove(props.match.params.id, () => {
      handleClose(true);
    });
  };

  const handleSubmit = () => {
    props.actions.save(getResourceToSave(resource), props.match.params.id, () => {
      handleClose(true);
    });
  };

  const {classes, match, loading, errors, data} = props;
  const title = match.params.id ? 'Edit manual import' : 'Create new manual import';
  const deleteFunc = match.params.id ? handleDelete : null;
  let oCustomers = [];
  let oSites = [];
  let oPays = [];
  let oCards = [];
  let oSubcategories = [];
  if (data !== undefined) {
    oCustomers = data.customers;
    oSites = data.sites;
    if (resource.customerId) {
      oSites = oSites.filter(x => x.customer.id === resource.customerId);
    }
    oPays = data.pays;
    oCards = data.cards_cash;
    oSubcategories = data.sale_subcategories;
  }
  const checksumValue = checksum();
  const buttons = match.params.id && resource.processed === null ?
          getFormButtons(handleClose, deleteFunc, handleSubmit, handleExecute, checksumValue !== 0) :
          getFormButtons(handleClose, undefined, resource.processed === null || resource.processed === undefined ? handleSubmit : undefined);
  return (loading ? <PeasLoading/> :
    <div>
      <SectionTitle label={title} buttons={buttons}/>
      <form className={classes.wideForm}>
        <FormGroup>
          <FormControl>
            <PeasAlert
              multiple
              variant="error"
              open={errors !== null && errors.length > 0}
              onClose={props.actions.resetError}
              messages={errors}/>
          </FormControl>
        </FormGroup>
        <Grid container spacing={24}>
          <Grid item xs={12} md={4}>
            <FormGroup>
              <FormControl>
                <PeasSelect
                  value={resource.customerId || ''}
                  onChange={handleSelectChange('customerId')}
                  label={'Customer'}
                  options={oCustomers}
                  name="customerId"/>
              </FormControl>
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormGroup>
              <FormControl>
                <PeasSelect
                  value={resource.siteId || ''}
                  onChange={handleSelectChange('siteId')}
                  label={'Site'}
                  options={oSites}
                  name="siteId"/>
              </FormControl>
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormGroup>
              <FormControl>
                <PeasDatePicker
                  label={'Business date'}
                  value={resource.businessDate !== undefined ? String(resource.businessDate) : ''}
                  onChange={handleChange('businessDate')}/>
              </FormControl>
            </FormGroup>
          </Grid>
        </Grid>
        <Typography variant="body1" className={classes.smallTopMargin}>
          Sale subcategories
        </Typography>
        <TableBody>
          <TableRow key={'p_1'}>
            <TableCell component="th" scope="row">No</TableCell>
            <TableCell width="150px">Name</TableCell>
            <TableCell>VAT</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
          {oSubcategories && oSubcategories.sort(sortByNumber('no')).map(pay => (
              <TableRow key={'p_' + pay.id} className={classes.formTableRow}>
                <TableCell width="70px">{pay.no}</TableCell>
                <TableCell width="150px">{pay.name}</TableCell>
                <TableCell>{pay.vat}</TableCell>
                <TableCell className={classes.smallVerticalPadding}>
                  <PeasTextField
                    className={classes.noMargin}
                    type="number"
                    id="businessDate"
                    label={''}
                    margin="dense"
                    style={{height: 26}}
                    inputProps={{
                      style: {textAlign: 'right'},
                    }}
                    value={resource.businessDate !== undefined ? String(getArrayValue('subcategories', pay.id)) : ''}
                    onChange={handleArrayChange('subcategories', pay.id, pay.account, pay.name)}/>
                </TableCell>
              </TableRow>
          ))}
        </TableBody>

        <Typography variant="body1" className={classes.smallTopMargin}>
          Pay in / Pay out
        </Typography>
        <TableBody>
          <TableRow key={'p_0'}>
            <TableCell component="th" scope="row">No</TableCell>
            <TableCell width="150px">Name</TableCell>
            <TableCell>VAT</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
          {oPays && oPays.sort(sortByName('name')).map(pay => (
              <TableRow key={'p_' + pay.id} className={classes.formTableRow}>
                <TableCell width="70px">{pay.no}</TableCell>
                <TableCell width="150px">{pay.name}</TableCell>
                <TableCell>{pay.vat}</TableCell>
                <TableCell className={classes.smallVerticalPadding}>
                  <PeasTextField
                    className={classes.noMargin}
                    id="businessDate"
                    type="number"
                    label={''}margin="dense"
                    style={{height: 26}}
                    inputProps={{
                      style: {textAlign: 'right'},
                    }}
                    value={resource.businessDate !== undefined ? String(getArrayValue('pays', pay.id)) : ''}
                    onChange={handleArrayChange('pays', pay.id, pay.account, pay.name)}/>
                </TableCell>
              </TableRow>
          ))}
        </TableBody>

        <Typography variant="body1" className={classes.smallTopMargin}>
        Credit cards / Cash
        </Typography>
        <TableBody>
          <TableRow key={'c_0'}>
            <TableCell component="th" scope="row">No</TableCell>
            <TableCell width="150px">Name</TableCell>
            <TableCell>VAT</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
          {oCards && oCards.sort(sortByName('name')).map(card => (
              <TableRow key={'c_' + card.id} className={classes.formTableRow}>
                <TableCell width="70px">{card.no}</TableCell>
                <TableCell width="150px">{card.name}</TableCell>
                <TableCell>{card.vat}</TableCell>
                <TableCell classes={classes.smallVerticalPadding}>
                  <PeasTextField
                    className={classes.noMargin}
                    id="businessDate"
                    type="number"
                    label={''}
                    margin="dense"
                    style={{height: 26}}
                    inputProps={{
                      style: {textAlign: 'right'},
                    }}
                    value={resource.businessDate !== undefined ? String(getArrayValue('cards', card.id)) : ''}
                    onChange={handleArrayChange('cards', card.id, card.account, card.name)}/>
                </TableCell>
              </TableRow>
          ))}
        </TableBody>



        <PeasTextField
            id="checksum"
            label={'Checksum (must be 0)'}
            value={
              checksumValue && !isNaN(checksumValue)
                ? Number(checksumValue).toFixed(2)
                : checksumValue
            }
            disabled={true}
            />

      </form>
    </div>
  );
};

function mapStateToProps(state) {
  const {loading, reasonCode, errors, data, customers} = state.manualImportForm;
  return {
    loading, reasonCode, errors, data, customers
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(formStyles)(ManualImportForm));