import React from 'react';
import {t} from '../../translationUtils';
import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import filterStyles from './filterStyles';

const styles = {
  ...filterStyles,
  root: {
    margin: '20px 0 0 0'
  }
};

const ActiveSelect = props => {
  const options = [
    {value: 'all', name: t('activeSelect.all')},
    {value: true, name: t('activeSelect.active')},
    {value: false, name: t('activeSelect.inactive')}
  ];
  return (
    <FormControl className={props.classes.root}>
      <Select
        className={props.classes.select}
        classes={{selectMenu: props.classes.selectMenu}}
        value={props.value}
        onChange={props.onChange}
        inputProps={{
          name: 'active',
          id: `active-simple`,
        }}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default withStyles(styles)(ActiveSelect);