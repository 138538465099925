import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {t} from '../common/translationUtils';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {sortByName} from '../common/sortUtils';
import styles from './styles';

const MonthlyReportsCustomers = props => {
  const {classes, customers, customerNumber, onCustomerClick} = props;
  return (
    <div>
      <Typography variant="h6">{t('common.customers')}</Typography>
        <List dense className={classes.customerList}>
          {customers.sort(sortByName('name', 'ASC')).map(customer => (
          <ListItem
            key={customer.id}
            button
            selected={customerNumber === customer.number}
            onClick={() => onCustomerClick(customer.number)}>
            <ListItemText primary={customer.name}/>
          </ListItem>))}
        </List>
    </div>
  );
};

export default withStyles(styles)(MonthlyReportsCustomers);
