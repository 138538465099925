import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PeasButton from '../common/components/forms/PeasButton';
import {t} from '../common/translationUtils';
import {routes} from '../routes';
import styles from '../common/styles/dashboardStyles';

class AdminDashboard extends React.Component {

  route = path => () => {
    this.props.history.push(path);
  }

  render() {
    const {classes} = this.props;

    const sectionButton = (label, handleClick) => (
      <PeasButton
        color={null}
        className={classes.sectionButton}
        variant="outlined"
        label={label}
        onClick={handleClick}
        disabled={handleClick === undefined}/>
    );

    return (
      <Grid container spacing={24}>
        <Grid item xs={12} md={3}>
          <Typography variant="h5" className={classes.subtitle}>
            {t('common.settings')}
          </Typography>
          {sectionButton(t('common.users'), this.route(routes.users.base))}
          {sectionButton(t('common.customers'), this.route(routes.customers.base))}
          {sectionButton(t('common.sites'), this.route(routes.sites.base))}

          <Typography variant="h5" className={classes.subtitle}>
            {t('common.dataImportExport')}
          </Typography>
          {sectionButton(t('common.importPOS'), this.route(routes.importPOSData.base))}
          {sectionButton(t('common.importCreditCard'), this.route(routes.importCreditCardData.base))}
          {sectionButton(t('common.importKredi'), this.route(routes.importKrediData.base))}
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="h5" className={classes.subtitle}>
          {t('common.interfaces')}
          </Typography>
          {sectionButton(t('common.reasonCodes'), this.route(routes.reasonCodes.base))}
          {sectionButton(t('common.categories'), this.route(routes.categories.base))}
          {sectionButton(t('common.subcategories'), this.route(routes.subcategories.base))}
          {sectionButton(t('common.products'), this.route(routes.products.base))}
        </Grid>
      </Grid>
    );
  }
};

export default withStyles(styles)(AdminDashboard);
