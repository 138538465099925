import React, {useState, useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {t} from '../../common/translationUtils';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PeasButton from '../../common/components/forms/PeasButton';
import PeasTextField from '../../common/components/forms/PeasTextField';
import formStyles from '../../common/styles/formStyles';

const SubcategoryAccountForm = props => {
  const {account, open, index} = props;
  const [resource, setResource] = useState({});

  useEffect(fillForm, [account]);

  function fillForm() {
    setResource(account ? account : {});
  };

  const isFilled = () => {
    const {taxRate, taxCode, accountNo, costAccount, stockAccount} = resource;
    return taxRate && taxCode && accountNo && costAccount && stockAccount;
  };

  const handleChange = field => event => {
    setResource({...resource, [field]: event.target.value});
  };

  const handleDelete = () => {
    props.handleDelete();
    props.handleClose();
  };

  const handleSubmit = () => {
    props.handleSubmit(resource);
    props.handleClose();
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="subcategory-account-form">
      <DialogTitle id="subcategory-account-form">
        {t('subcategory.account')}
      </DialogTitle>
      <DialogContent>
        <form>
          <PeasTextField
            id="taxRate"
            label={t('subcategory.label.taxRate')}
            value={resource.taxRate || ''}
            onChange={handleChange('taxRate')}/>
          <PeasTextField
            id="taxCode"
            label={t('common.code')}
            value={resource.taxCode || ''}
            onChange={handleChange('taxCode')}/>
          <PeasTextField
            id="accountNo"
            label={t('subcategory.label.accountNo')}
            value={resource.accountNo || ''}
            onChange={handleChange('accountNo')}/>
          <PeasTextField
            id="costAccount"
            label={t('subcategory.label.costAccount')}
            value={resource.costAccount || ''}
            onChange={handleChange('costAccount')}/>
          <PeasTextField
            id="stockAccount"
            label={t('subcategory.label.stockAccount')}
            value={resource.stockAccount || ''}
            onChange={handleChange('stockAccount')}/>
        </form>
      </DialogContent>
      <DialogActions>
        <PeasButton
          color={null}
          label={t('common.button.cancel')}
          onClick={props.handleClose}/>
        {index >= 0 ? <PeasButton
          color="secondary"
          label={t('common.button.delete')}
          onClick={handleDelete}/> : null}
        <PeasButton
          color="primary"
          label={t('common.button.save')}
          onClick={handleSubmit}
          disabled={!isFilled()}/>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(formStyles)(SubcategoryAccountForm);