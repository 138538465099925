import * as actionNames from './actionNames';
import * as loginUtils from '../common/loginUtils';

let user = loginUtils.getUser();
const initialState = {
  loggingIn: false,
  loggedIn: user !== null,
  user: user ? user : null,
  error: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case actionNames.LOGIN_REQUEST:
    return {
      loggingIn: true,
      error: null
    };
  case actionNames.LOGIN_SUCCESS:
    return {
      loggingIn: false,
      loggedIn: true,
      user: action.user,
      error: null
    };
  case actionNames.LOGIN_FAILURE:
    return {
      loggingIn: false,
      loggedIn: false,
      error: action.error
    };
  case actionNames.LOGIN_RESET_ERROR:
    return {
      error: null
    };
  case actionNames.LOGIN_LOGOUT:
    return {
      loggingIn: false,
      loggedIn: false,
      error: null
    };
  default:
    return state
  }
};
