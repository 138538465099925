import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {routes} from '../../routes';
import {t} from '../../common/translationUtils';
import {withStyles} from '@material-ui/core/styles';
import SectionTitle from '../../common/components/layout/SectionTitle';
import PeasLoading from '../../common/components/forms/PeasLoading';
import PeasTextField from '../../common/components/forms/PeasTextField';
import PeasAlert from '../../common/components/forms/PeasAlert';
import PeasMultiSelect from '../../common/components/forms/PeasMultiSelect';
import PeasCheckbox from '../../common/components/forms/PeasCheckbox';
import * as actions from './actions';
import {getFormButtons, getEmptyActiveResource} from  '../../common/formUtils';
import * as loginUtils from '../../common/loginUtils';
import formStyles from '../../common/styles/formStyles';

const UserForm = props => {
  const [resource, setResource] = useState(getEmptyActiveResource(props));
  useEffect(init, []);
  useEffect(fillForm, [props.user]);

  function init() {
    const {id} = props.match.params;
    props.actions.loadResources(id);
  };

  function fillForm() {
    setResource(props.user ? props.user : getEmptyActiveResource(props));
  };

  const handleClose = withSuccess => {
    const route = withSuccess === true ? `${routes.users.base}?success=true` : routes.users.base;
    props.history.push(route);
  };

  const handleChange = field => event => {
    setResource({...resource, [field]: event.target.value});
  };

  const handleActiveChange = event => {
    setResource({...resource, active: event.target.checked});
  };

  const handleSelectChange = field => event => {
    const ids = event.target.value;
    setResource({...resource, [field]: ids ? ids.map(id => ({id})) : []});
  };

  const handleSubmit = () => {
    props.actions.save(resource, props.match.params.id, () => {
      handleClose(true);
    });
  };

  const isAdminChosen = () => {
    const {allRoles} = props;
    const userRoles = resource.roles;
    const adminRoleId = allRoles ? allRoles.find(role => role.name === loginUtils.roles.ADMIN).id : null;
    return userRoles ? userRoles.map(role => role.id).includes(adminRoleId) : false;
  };

  const {classes, match, allRoles, allCustomers, loading, errors} = props;
  const {active, firstname, lastname, email, username, password, roles, customers} = resource;
  const title = match.params.id ? t('user.title.edit') : t('user.title.create');
  const buttons = getFormButtons(handleClose, null, handleSubmit);

  return (loading ? <PeasLoading/> :
    <div>
      <SectionTitle label={title} buttons={buttons}/>
      <form className={classes.form}>
        <PeasAlert
          multiple
          variant="error"
          open={errors !== null && errors.length > 0}
          onClose={props.actions.resetError}
          messages={errors}
        />
        <PeasCheckbox
          checked={active || false}
          onChange={handleActiveChange}
          value="active"
          label={t('common.active')}/>
        <PeasTextField
          required
          id="firstName"
          label={t('user.label.firstname')}
          value={firstname || ''}
          onChange={handleChange('firstname')}
        />
        <PeasTextField
          required
          id="lastName"
          label={t('user.label.lastname')}
          value={lastname || ''}
          onChange={handleChange('lastname')}
        />
        <PeasTextField
          required
          id="email"
          label={t('common.email')}
          value={email || ''}
          onChange={handleChange('email')}
        />
        <PeasTextField
          required
          id="loginName"
          label={t('user.label.loginname')}
          value={username || ''}
          onChange={handleChange('username')}
        />
        <PeasTextField
          required
          id="password"
          type="password"
          label={t('common.password')}
          value={password || ''}
          onChange={handleChange('password')}
        />
        <PeasMultiSelect
          required
          value={roles || ''}
          onChange={handleSelectChange('roles')}
          label={t('common.role')}
          options={allRoles}
          name="role"/>
        {isAdminChosen() ? null :
          <PeasMultiSelect
            value={customers || ''}
            onChange={handleSelectChange('customers')}
          label={t('common.customer')}
            options={allCustomers}
            name="customers"/>}
      </form>
    </div>
  );
};

function mapStateToProps(state) {
  const {loading, customers, user, errors} = state.userForm;
  return {
    loading, user, errors,
    allCustomers: customers,
    allRoles: state.app.roles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(formStyles)(UserForm));