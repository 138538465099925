import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {TableCell} from '@material-ui/core';
import tableStyles from '../../styles/tableStyles';

const PeasTableCell = props => {
  const {header, actions, classes} = props;
  const cellContent = props.children || props.label || props.value;
  
  const getClassName = () => {
    if (header) {
      return classes.tableHeader;
    }
    if (actions) {
      return classes.tableActions;
    }
    return null;
  }; 

  return (
    <TableCell
      padding="dense"
      className={getClassName()}
      width={props.width}
      component={props.component}
      scope={props.scope}>
      {cellContent}
    </TableCell>
  );
};

export default withStyles(tableStyles)(PeasTableCell);