import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const styles = {
  selected: {
    /* Necessary !important to override default material UI styles */
    backgroundColor: 'white !important'
  }
};

const PeasMultiSelect = props => {
  const value = props.value ? props.value.map(element => element.id) : [];
  const {classes, options} = props;
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel htmlFor={`${props.name}-simple`} required={props.required}>
        {props.label}
      </InputLabel>
      <Select
        multiple
        value={value}
        onChange={props.onChange}
        inputProps={{
          name: props.name,
          id: `${props.name}-simple`,
        }}
        renderValue={selected => {
          return selected.map(id => {
            return options ? options.find(option => option.id === id).name : '';
          }).join(', ');
        }}
      >
        {props.options ? props.options.map(option => (
          <MenuItem key={option.id} value={option.id} classes={{selected: classes.selected}}>
            <Checkbox color="primary" checked={value.indexOf(option.id) > -1} />
            <ListItemText value={option.id} primary={option.name} />
          </MenuItem>
        )) : null}
      </Select>
    </FormControl>
  );
};

export default withStyles(styles)(PeasMultiSelect);