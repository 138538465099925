import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import {useList} from '../../common/hooks/useList';
import {t} from '../../common/translationUtils';
import {routes} from '../../routes';
import * as listUtils from '../../common/listUtils';
import tableStyles from '../../common/styles/tableStyles';
import posStyles from '../styles';
import PeasTable from '../../common/components/list/PeasTable';
import PeasTableCell from '../../common/components/list/PeasTableCell';
import {TableBody, TableHead, TableRow} from '@material-ui/core';
import SectionTitle from '../../common/components/layout/SectionTitle';
import PayloadModal from '../components/PayloadModal';
import RequestPayloadButton from '../components/RequestPayloadButton';
import {getClassByStatus, formatRequestDate} from '../selectors';
import * as actions from './actions';

const ImportKrediHistory = props => {
  const {requestId, itemId} = props.match.params;
  const {itemHistory, paginationConfig, actions, history, loading, classes} = props;

  const [payload, setPayload] = useState(false);
  const [, pagination] = useList({
    loader: actions.loadHistory,
    loadParams: {requestId, itemId},
    paginationConfig,
    history
  });

  return (
    <div>
      <SectionTitle
        label={t('importKredi.history.title', {id: itemId})}
        buttons={listUtils.getListButtons({
          backRoute: `${routes.importKrediData.base}/${requestId}`,
          backLabel: 'common.button.back'
        }, props)}/>
      <PeasTable
        className={classes.tableContainer}
        list={itemHistory}
        pagination={pagination}
        loading={loading}>
        <TableHead>
          <TableRow>
            <PeasTableCell header label={t('common.time')}/>
            <PeasTableCell header label={t('common.status')}/>
            <PeasTableCell header label={t('operations.label.requestPayload')}/>
            <PeasTableCell header label={t('operations.label.responsePayload')}/>
          </TableRow>
        </TableHead>
        <TableBody>
          {itemHistory.map(entry => (
            <TableRow key={entry.itemHistoryId}>
              <PeasTableCell component="th" scope="row" value={formatRequestDate(entry.requestTime)}/>
              <PeasTableCell>
                <span className={getClassByStatus(entry.status, classes)}>{entry.status}</span>
              </PeasTableCell>
              <PeasTableCell>
                <RequestPayloadButton setPayload={setPayload} payload={entry.requestPayload}/>
              </PeasTableCell>
              <PeasTableCell>
                <RequestPayloadButton setPayload={setPayload} payload={entry.responsePayload}/>
              </PeasTableCell>
            </TableRow>
          ))}
          <PayloadModal
            open={Boolean(payload)}
            payload={payload}
            handleClose={() => setPayload(false)}/>
        </TableBody>
      </PeasTable>
    </div>
  );
};

function mapStateToProps(state) {
  const {loading, itemHistory, paginationConfig} = state.importKrediHistory;
  return {loading, itemHistory, paginationConfig};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles({
  ...tableStyles,
  ...posStyles
})(ImportKrediHistory));