/* 
  HELPERS FOR GENERIC LISTS
  You may use these functions if you properly set
  backRoute and mainRoute in component's state, for example:
  this.state = {
    backRoute: routes.adminDashboard.base,
    mainRoute: routes.users
  };
  Then you can use getListButtons to get header buttons
  and assign handleEdit to every table row.
 */
import React from 'react';
import {t} from '../common/translationUtils';
import PeasButton from './components/forms/PeasButton';

export const removeSuccessMessage = (state, props) => () => {
  props.history.push(state.mainRoute.base);
};

export const handleClose = (state, props) => () => {
  props.history.push(state.backRoute);
};

export const handleCreate = (state, props) => () => {
  props.history.push(state.mainRoute.form);
};

export const handleEdit = (id, state, props) => () => {
  props.history.push(`${state.mainRoute.form}/${id}`);
}

export const getListButtons = (state, props) => {
  return [
    <PeasButton
      color={null}
      label={t(state.backLabel || 'common.button.cancel')}
      onClick={handleClose(state, props)}/>,
    ...(state.mainRoute && state.mainRoute.form ? [<PeasButton
      label={t(state.createLabel || 'common.button.create')}
      onClick={handleCreate(state, props)}/>] : [])
  ];
};
