import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {routes} from '../../routes';
import {t} from '../../common/translationUtils';
import {withStyles} from '@material-ui/core/styles';
import SectionTitle from '../../common/components/layout/SectionTitle';
import PeasLoading from '../../common/components/forms/PeasLoading';
import PeasTextField from '../../common/components/forms/PeasTextField';
import PeasSelect from '../../common/components/forms/PeasSelect';
import PeasCheckbox from '../../common/components/forms/PeasCheckbox';
import PeasAlert from '../../common/components/forms/PeasAlert';
import * as actions from './actions';
import {getFormButtons, getEmptyActiveResource} from  '../../common/formUtils';
import formStyles from '../../common/styles/formStyles';

const CustomerForm = props => {
  const [resource, setResource] = useState(getEmptyActiveResource(props));
  useEffect(init, []);
  useEffect(fillForm, [props.customer]);

  function init() {
    const {id} = props.match.params;
    props.actions.loadResources(id);
  }

  function fillForm() {
    setResource(props.customer ? props.customer : getEmptyActiveResource(props));
  };

  const handleClose = isSuccess => {
    const route = isSuccess === true ? `${routes.customers.base}?success=true` : routes.customers.base;
    props.history.push(route);
  };

  const handleChange = field => event => {
    setResource({...resource, [field]: event.target.value});
  };

  const handleSelectChange = field => event => {
    const {value} = event.target;
    setResource({...resource, [field]: value});
  };

  const handleActiveChange = event => {
    setResource({...resource, active: event.target.checked});
  };

  const handleDelete = () => {
    props.actions.remove(props.match.params.id, () => {
      handleClose(true);
    });
  };

  const handleSubmit = () => {
    props.actions.save(resource, props.match.params.id, () => {
      handleClose(true);
    });
  };

  const {classes, errors, match, loading, customer} = props;
  const editMode = match.params.id;
  const deleteFunc = editMode && customer && customer.commentsCount === 0 ? handleDelete : null;
  const title = editMode ? t('customer.title.edit') : t('customer.title.create');
  const buttons = getFormButtons(handleClose, deleteFunc, handleSubmit);
  const languageOptions = [
    {id: 'en', name: t('common.language.en')},
    {id: 'de', name: t('common.language.de')},
    {id: 'fr', name: t('common.language.fr')},
    {id: 'it', name: t('common.language.it')}
  ];

  return (loading ? <PeasLoading/> :
    <div>
      <SectionTitle label={title} buttons={buttons}/>
      <form className={classes.form}>
        <PeasAlert
          multiple
          variant="error"
          open={errors !== null && errors.length > 0}
          onClose={props.actions.resetError}
          messages={errors}
        />
        <PeasCheckbox
          checked={resource.active || false}
          onChange={handleActiveChange}
          value="active"
          label={t('common.active')}/>
        <PeasTextField
          required
          id="organisationId"
          label={t('customer.label.organisationId')}
          value={resource.number || ''}
          onChange={handleChange('number')}
        />
        <PeasTextField
          required
          id="clusterId"
          label={t('customer.label.clusterId')}
          value={resource.clusterId || ''}
          onChange={handleChange('clusterId')}
        />
        <PeasTextField
          required
          id="legalName"
          label={t('customer.label.legalName')}
          value={resource.name || ''}
          onChange={handleChange('name')}
        />
        <PeasTextField
          id="street"
          label={t('customer.label.street')}
          value={resource.street || ''}
          onChange={handleChange('street')}
        />
        <PeasTextField
          id="postalCode"
          label={t('customer.label.zip')}
          value={resource.zip || ''}
          onChange={handleChange('zip')}
        />
        <PeasTextField
          id="city"
          label={t('customer.label.city')}
          value={resource.city || ''}
          onChange={handleChange('city')}
        />
        <PeasTextField
          id="country"
          label={t('customer.label.country')}
          value={resource.country || ''}
          onChange={handleChange('country')}
        />
        <PeasTextField
          required
          id="vatNumber"
          label={t('customer.label.vatNumber')}
          value={resource.vatNumber || ''}
          onChange={handleChange('vatNumber')}
        />
        <PeasTextField
          required
          id="transferAccount"
          label={t('customer.label.transferAccount')}
          value={resource.transfer_account || ''}
          onChange={handleChange('transfer_account')}
        />
        <PeasTextField
          id="shellCreditorNo"
          label={t('customer.label.shellCreditorNo')}
          value={resource.shellCreditorNo || ''}
          onChange={handleChange('shellCreditorNo')}
        />
        <PeasSelect
          value={resource.language || ''}
          onChange={handleSelectChange('language')}
          label={t('common.language')}
          options={languageOptions}
          name="language"/>
      </form>
    </div>
  );
};

function mapStateToProps(state) {
  const {loading, customer, errors} = state.customerForm;
  return {
    loading, customer, errors
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(formStyles)(CustomerForm));
