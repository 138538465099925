import * as actionNames from './actionNames';
import axios from 'axios';
import {handleError, getArrayFromRequest} from '../common/serverUtils';
import {OPERATIONS_CREDIT_CARD_ENDPOINT} from '../endpoints';
import {
  getFilters, getLikeFilter, getPageFilter, getSizeFilter, getSortFilter, addFiltersToUrl
} from '../common/filterUtils';
import {DEFAULT_PAGE_SIZE} from './selectors';

export const loadOperations = (filters, page) => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    const filterQuery = getFilters([
      getLikeFilter('siteName', filters.siteName),
      getLikeFilter('siteId', filters.siteId),
      getLikeFilter('status', filters.status),
      getPageFilter(page),
      getSizeFilter(DEFAULT_PAGE_SIZE),
      getSortFilter('requestTime', 'desc')
    ]);

    try {
      const response = await axios.get(addFiltersToUrl(OPERATIONS_CREDIT_CARD_ENDPOINT, filterQuery));
      dispatch({
        type: actionNames.LOADED, 
        operations: getArrayFromRequest(response, 'auditCreditCardResourceList'),
        paginationConfig: response.data ? response.data.page : null
      });
    } catch (error) {
      handleError(error, dispatch);
    };
  };
};

export const restartRequest = (operationId, itemId) => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    try {
      const encodedId = encodeURIComponent(operationId);
      const link = `${OPERATIONS_CREDIT_CARD_ENDPOINT}/${encodedId}/items/${itemId}/restart`;
      const response = await axios.get(link);
      dispatch({
        type: actionNames.REQUEST_TRIGGERED,
        triggerResponse: response
      });
    } catch (error) {
      handleError(error, dispatch);
    };
  };
};

export const resetTriggerMessage = () => {
  return async dispatch => {
    dispatch({
      type: actionNames.TRIGGER_MESSAGE_REMOVED,
      triggerResponse: null
    });
  };
};