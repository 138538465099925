import * as actionNames from './actionNames';
import {DEFAULT_PAGE_SIZE} from '../selectors';

const initialState = {
  loading: false,
  itemHistory: [],
  paginationConfig: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case actionNames.LOADING:
    return {
      ...state,
      loading: true
    };
  case actionNames.LOADED:
    return {
      ...state,
      loading: false,
      itemHistory: action.itemHistory,
      paginationConfig: {
        ...action.paginationConfig,
        size: DEFAULT_PAGE_SIZE
      }
    };
  default:
    return state
  }
};
