import React from 'react';
import {t} from '../../common/translationUtils';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PeasButton from '../../common/components/forms/PeasButton';

const PayloadModal = props => {
  const {open, payload, maxWidth} = props;
  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth || "md"}
      open={open}
      aria-labelledby="payload-modal">
      <DialogTitle id="payload-modal">
        {t('common.payload')}
      </DialogTitle>
      <DialogContent>
        <pre>{payload}</pre>
      </DialogContent>
      <DialogActions>
        <PeasButton
          color={null}
          label={t('common.button.cancel')}
          onClick={props.handleClose}/>
      </DialogActions>
    </Dialog>
  );
};

export default PayloadModal;
