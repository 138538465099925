import { combineReducers } from 'redux';
import app from './app/reducers';
import changePassword from './changePassword/reducers';
import login from './login/reducers';
import users from './users/reducers';
import userForm from './users/form/reducers';
import customers from './customers/reducers';
import customerForm from './customers/form/reducers';
import comments from './comments/reducers';
import commentForm from './comments/form/reducers';
import subcategories from './subcategories/reducers';
import subcategoryForm from './subcategories/form/reducers';
import manualImports from './manualImport/reducers';
import manualImportForm from './manualImport/form/reducers';
import products from './products/reducers';
import productsForm from './products/form/reducers';
import categories from './categories/reducers';
import categoryForm from './categories/form/reducers';
import sites from './sites/reducers';
import siteForm from './sites/form/reducers';
import reasonCodes from './reasonCodes/reducers';
import reasonCodeForm from './reasonCodes/form/reducers';
import importPOSData from './importPOSData/reducers';
import importPOSItems from './importPOSData/items/reducers';
import importPOSHistory from './importPOSData/history/reducers';
import importKrediData from './importKrediData/reducers';
import importKrediItems from './importKrediData/items/reducers';
import importKrediHistory from './importKrediData/history/reducers';
import importCreditCardData from './importCreditCardData/reducers';
import importCreditCardItems from './importCreditCardData/items/reducers';
import importCreditCardHistory from './importCreditCardData/history/reducers';
import monthlyReports from './monthlyReports/reducers';

export default combineReducers({
    app,
    login,
    changePassword,
    users,
    userForm,
    customers,
    customerForm,
    comments,
    commentForm,
    manualImports,
    manualImportForm,
    sites,
    siteForm,
    subcategories,
    subcategoryForm,
    products,
    productsForm,
    categories,
    categoryForm,
    reasonCodes,
    reasonCodeForm,
    importPOSData,
    importPOSItems,
    importPOSHistory,
    importCreditCardData,
    importCreditCardItems,
    importCreditCardHistory,
    importKrediData,
    importKrediItems,
    importKrediHistory,
    monthlyReports
});
