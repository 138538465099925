export default theme => ({
  subtitle: {
    margin: '30px 0 20px 0',
    fontSize: '0.9rem',
    color: 'gray',
    textTransform: 'uppercase'
  },
  sectionButton: {
    display: 'block',
    width: '100%',
    marginBottom: '10px',
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
      width: '200px'
    }
  }
});
