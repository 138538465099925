import * as actionNames from './actionNames';

const initialState = {
  loading: false,
  comments: null,
  users: null,
  customer: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case actionNames.LOADING:
    return {
      loading: true
    };
  case actionNames.LOADED:
    return {
      loading: false,
      comments: action.comments,
      users: action.users,
      customer: action.customer
    };
  default:
    return state
  }
};
