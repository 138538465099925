import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import {useList} from '../../common/hooks/useList';
import {t} from '../../common/translationUtils';
import {routes} from '../../routes';
import PeasTable from '../../common/components/list/PeasTable';
import PeasTableCell from '../../common/components/list/PeasTableCell';
import {TableBody, TableHead, TableRow} from '@material-ui/core';
import SectionTitle from '../../common/components/layout/SectionTitle';
import * as listUtils from '../../common/listUtils';
import tableStyles from '../../common/styles/tableStyles';
import posStyles from '../styles';
import ImportKrediStatusSelect from '../components/ImportKrediStatusSelect';
import PayloadModal from '../components/PayloadModal';
import RestartItemAlert from '../components/RestartItemAlert';
import RequestPayloadButton from '../components/RequestPayloadButton';
import ImportKrediActionButton from '../components/ImportKrediActionButton';
import {getClassByStatus, TABLE_ACTION} from '../selectors';
import * as actions from './actions';

const ImportKrediItems = props => {
  const {requestId} = props.match.params;
  const {items, paginationConfig, triggerResponse, history, classes, actions, loading} = props;

  const [payload, setPayload] = useState(false);
  const [filterer, pagination] = useList({
    loader: actions.loadItems,
    loadParams: {requestId},
    defaultFilters: {status: 'ALL'},
    paginationConfig,
    history
  });

  const handleTrigger = id => () => {
    actions.triggerItem(requestId, id, () => {
      actions.loadItems(filterer.filters, pagination.page, {requestId});
    });
  };

  const redirectToHistory = itemId => {
    history.push(routes.importKrediData.history(requestId, itemId));
  };

  return (
    <div>
      <SectionTitle
        label={t('importKredi.item.title', {id: requestId})}
        buttons={listUtils.getListButtons({
          backRoute: routes.importKrediData.base,
          backLabel: 'common.button.back'
        }, props)}/>
      <RestartItemAlert response={triggerResponse} actions={actions}/>
      <ImportKrediStatusSelect
        value={filterer.get('status')}
        onChange={e => filterer.set('status', e.target.value)}/>
      <PeasTable
        className={classes.tableContainer}
        list={items}
        pagination={pagination}
        loading={loading}>
        <TableHead>
          <TableRow>
            <PeasTableCell header label={t('common.id')}/>
            <PeasTableCell header label={t('importKrediItem.table.customerName')}/>
            <PeasTableCell header label={t('importKrediItem.table.siteId')}/>
            <PeasTableCell header label={t('importKrediItem.table.documentId')}/>
            <PeasTableCell header label={t('importKrediItem.table.invoiceDate')}/>
            <PeasTableCell header label={t('common.status')}/>
            <PeasTableCell header label={t('operations.label.requestPayload')}/>
            <PeasTableCell header label={t('operations.label.responsePayload')}/>
            <PeasTableCell header/>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(item => (
            <TableRow key={item.itemId}>
              <PeasTableCell width="30px" component="th" scope="row" value={item.itemId}/>
              <PeasTableCell width="120px" value={item.customerName}/>
              <PeasTableCell width="30px" value={item.siteNumber}/>
              <PeasTableCell width="30px" value={item.documentId}/>
              <PeasTableCell width="68px" value={item.invoiceDate}/>
              <PeasTableCell>
                <span className={getClassByStatus(item.status, classes)}>{item.status}</span>
              </PeasTableCell>
              <PeasTableCell>
                <RequestPayloadButton setPayload={setPayload} payload={item.requestPayload}/>
              </PeasTableCell>
              <PeasTableCell>
                <RequestPayloadButton setPayload={setPayload} payload={item.responsePayload}/>
              </PeasTableCell>
              <PeasTableCell actions>
                {item.status === 'FAILED' || item.status === 'SEND_TO_ABACUS' ?
                  <ImportKrediActionButton
                    type={TABLE_ACTION.RESTART}
                    action={handleTrigger(item.itemId)}/> : 
                  null
                }
                <ImportKrediActionButton
                  type={TABLE_ACTION.SHOW_HISTORY}
                  action={() => redirectToHistory(item.itemId)}/>
              </PeasTableCell>
            </TableRow>
          ))}
        </TableBody>
      </PeasTable>
      <PayloadModal
        open={Boolean(payload)}
        payload={payload}
        handleClose={() => setPayload(false)}/>
    </div>
  );
};

function mapStateToProps(state) {
  const {loading, triggerResponse, items, paginationConfig} = state.importKrediItems;
  return {loading, triggerResponse, items, paginationConfig};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles({
  ...tableStyles,
  ...posStyles
})(ImportKrediItems));
