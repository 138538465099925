import React from 'react';
import PeasAlert from '../../common/components/forms/PeasAlert';
import {getTriggerMessage} from '../selectors';

const RestartItemAlert = ({response, actions}) => {
  return (
    <PeasAlert
      variant={response && response.status === 200 ? 'success' : 'error'}
      open={response !== null}
      onClose={actions.resetTriggerMessage}
      message={response ? getTriggerMessage(response) : ''}/>
  );
};

export default RestartItemAlert;