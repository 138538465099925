export default {
  root: {
    maxWidth: '300px',
    margin: '0px auto',
    padding: '100px 0px'
  },
  logo: {
    display: 'block',
    margin: '0 auto 20px auto'
  },
  loginButton: {
    margin: '30px 0px'
  },
  logoPlaceholder: {
    textAlign: 'center'
  }
};
