import * as actionNames from './actionNames';
import {DEFAULT_PAGE_SIZE} from '../selectors';

const initialState = {
  loading: false,
  items: [],
  triggerResponse: null,
  paginationConfig: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case actionNames.LOADING:
    return {
      ...state,
      loading: true
    };
  case actionNames.LOADED:
    return {
      ...state,
      loading: false,
      items: action.items,
      paginationConfig: {
        ...action.paginationConfig,
        size: DEFAULT_PAGE_SIZE
      }
    };
  case actionNames.REQUEST_TRIGGERED:
    return {
      ...state,
      loading: false,
      triggerResponse: action.triggerResponse
    }
  case actionNames.TRIGGER_MESSAGE_REMOVED:
    return {
      ...state,
      triggerResponse: null
    }
  default:
    return state
  }
};
