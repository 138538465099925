import {parse, addMonths} from 'date-fns/esm';
import {t} from '../../common/translationUtils';
import {createValidation, validateFields} from '../../common/validationsUtils';

const errorMessages = {
  NOTES_ERROR: 'comment.error.notesLength',
  COMPLETION_DATE_ERROR: 'comment.error.completionDate'
};

const fields = {
  'period': createValidation('comment.label.period', 'date', true),
  'completionDate': createValidation('comment.label.completionDate', 'date', true),
  'notes': createValidation('common.notes', 'text', true)
}

export const validate = resource => {
  const {errors} = validateFields(fields, resource);
  const {completionDate, period, notes} = resource;
  if (notes && notes.length < 20) {
    errors.push(t(errorMessages.NOTES_ERROR));
  }
  if (completionDate && period) {
    const parsedCompletionDate = parse(completionDate, 'yyyy-MM-dd', new Date());
    const parsedPeriod = parse(period, 'yyyy-MM-dd', new Date());
    if (parsedCompletionDate < addMonths(parsedPeriod, 1)) {
      errors.push(t(errorMessages.COMPLETION_DATE_ERROR));
    }
  }
  return {valid: errors.length === 0, errors};
};