import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import {routes} from '../routes';
import {t} from '../common/translationUtils';
import SectionTitle from '../common/components/layout/SectionTitle';
import PeasLoading from '../common/components/forms/PeasLoading';
import PeasTextField from '../common/components/forms/PeasTextField';
import PeasAlert from '../common/components/forms/PeasAlert';
import * as actions from './actions';
import {getFormButtons} from  '../common/formUtils';
import {getUser, roles} from '../common/loginUtils';
import formStyles from '../common/styles/formStyles';

const ChangePassword = props => {
  const [resource, setResource] = useState({});
  useEffect(init, []);

  function init() {
    props.actions.resetError();
  };

  const handleChange = field => event => {
    setResource({...resource, [field]: event.target.value});
  };

  const handleClose = () => {
    const user = getUser();
    if (user.roles.includes(roles.ADMIN)) {
      props.history.push(routes.adminDashboard.base);
    } else {
      props.history.push(routes.reports.base);
    }
  };

  const handleSubmit = () => {
    props.actions.save(resource);
  };

  const {classes, saved, loading, errors} = props;

  return (loading ? <PeasLoading/> :
    <div>
      <SectionTitle
        label={t('changePassword.title')}
        buttons={getFormButtons(handleClose, null, handleSubmit)}/>
      <form className={classes.form}>
        <PeasAlert
          multiple
          variant="error"
          open={errors !== null && errors.length > 0}
          onClose={props.actions.resetError}
          messages={errors}
        />
        <PeasAlert
          variant="success"
          open={saved}
          onClose={props.actions.resetError}
          message={t('changePassword.message.success')}
        />
        <PeasTextField
          required
          id="password"
          type="password"
          label={t('changePassword.label.currentPassword')}
          value={resource.password}
          onChange={handleChange('password')}
        />
        <PeasTextField
          required
          id="newPassword"
          type="password"
          label={t('changePassword.label.newPassword')}
          value={resource.newPassword}
          onChange={handleChange('newPassword')}
        />
        <PeasTextField
          required
          id="newPassword2"
          type="password"
          label={t('changePassword.label.confirmPassword')}
          value={resource.newPassword2}
          onChange={handleChange('newPassword2')}
        />
      </form>
    </div>
  );
};

function mapStateToProps(state) {
  const {loading, saved, errors} = state.changePassword;
  return {
    loading, saved, errors
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(formStyles)(ChangePassword));