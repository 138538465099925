export const mainMenuStyles = theme => ({
  toolbar: {
    margin: '0 auto',
    padding: '0',
    minHeight: '0',
    maxWidth: '1170px',
    width: '100%'
  },
  tab: {
    fontSize: '0.7rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.815rem'
    }
  },
  emptyTab: {
    display: 'none'
  },
  logo: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      height: '32px',
      padding: '0px 15px'
    }
  },
  editSection: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      position: 'absolute',
      right: '130px',
    }
  },
  editSectionText: {
    margin: '0 5px',
    display: 'inline-block',
    fontSize: '0.8rem',
    verticalAlign: 'middle',
    color: 'gray'
  },
  languageSection: {
    position: 'absolute',
    right: '70px',
  },
  languageSelect: {
    fontSize: '0.9rem',
    color: 'gray',
    border: '1px solid transparent !important',
    '&:before, &:hover:before, &:after': {border: '0 !important'}
  },
  languageSelectMenu: {
    padding: '6px 25px 6px 10px',
    background: 'transparent !important'
  },
  logoutButton: {
    position: 'absolute',
    right: '15px',
    color: '#aaa'
  }
});

export const layoutStyles = theme => ({
  sectionButtons: {
    zIndex: '2',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    textAlign: 'center',
    background: '#f8f8f8',
    borderTop: '1px solid #eee',
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
      background: 'initial',
      border: '0',
      textAlign: 'right'
    }
  },
  sectionButton: {
    margin: '0px 8px'  
  },
  sectionSubtitle: {
    fontSize: '0.9rem',
    color: '#aaa'
  }
});