import * as actionNames from './actionNames';
import {DEFAULT_PAGE_SIZE} from './selectors';

const initialState = {
  loading: false,
  operations: null,
  triggerResponse: null,
  paginationConfig: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case actionNames.LOADING:
    return {
      ...state,
      loading: true
    };
  case actionNames.REQUEST_TRIGGERED:
    return {
      ...state,
      loading: false,
      triggerResponse: action.triggerResponse
    };
  case actionNames.TRIGGER_MESSAGE_REMOVED:
    return {
      ...state,
      triggerResponse: null
    };
  case actionNames.LOADED:
    return {
      ...state,
      loading: false,
      operations: action.operations,
      paginationConfig: {
        ...action.paginationConfig,
        size: DEFAULT_PAGE_SIZE
      }
    };
    case actionNames.LOADED_POPUP:
      return {
        ...state,
        loading: false,
        popupContent: action.payload
      };
    case actionNames.CLOSE_POPUP:
      return {
        ...state,
        loading: false,
        popupContent: null
      };
  default:
    return state
  }
};
