import React from 'react';
import Button from '@material-ui/core/Button';

const PeasButton = props => (
  <Button
    variant="contained"
    color="primary"
    {...props}>
    {props.label}
  </Button>
);

export default PeasButton;