import * as actionNames from './actionNames';

const initialState = {
  loading: false,
  reasonCode: null,
  customers: [],
  errors: [],
  tab: 0
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case actionNames.LOADING:
    return {
      ...state,
      loading: true,
      errors: []
    };
  case actionNames.LOADED_RESOURCES:
    return {
      ...state,
      loading: false,
      reasonCode: action.reasonCode
    };
    case actionNames.LOADING_MAIN:
      return {
        ...state,
        loading: true,
        errors: []
      };
  case actionNames.LOADED_MAIN_RESOURCES:
    return {
      ...state,
      loading: false,
      data: action.data,
      customers: action.customers
    };
  case actionNames.SAVED:
    return {
      ...state,
      loading: false
    };
  case actionNames.REMOVED:
    return {
      ...state,
      loading: false,
      reasonCode: null
    };
  case actionNames.ERROR:
    return {
      ...state,
      loading: false,
      errors: action.errors
    };
  case actionNames.RESET_ERROR:
    return {
      ...state,
      errors: []
    };
  default:
    return state
  }
};
