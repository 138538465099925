import {createValidation, validateFields} from '../../common/validationsUtils';

const fields = {
  'number': createValidation('customer.label.organisationId', 'number', true),
  'clusterId': createValidation('customer.label.clusterId', 'number', true),
  'name': createValidation('common.name', 'text', true),
  'vatNumber': createValidation('customer.label.vatNumber', 'text', true),
  'transfer_account': createValidation('customer.label.transferAccount', 'text', true),
  'shellCreditorNo': createValidation('customer.label.shellCreditorNo', 'number', true)
}

export const validate = resource => {
  return validateFields(fields, resource);
};
