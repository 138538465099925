import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import tableStyles from '../../styles/tableStyles';

const EmptyList = props => {
  const {items, label, classes} = props;
  const displayItems = items && items.length > 0;
  return (
    !displayItems && <Typography variant="body1" className={classes.tableNoEntries}>
      {label}
    </Typography>
  );
};

export default withStyles(tableStyles)(EmptyList);
