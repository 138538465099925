import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {routes} from '../../routes';
import * as loginUtils from '../../common/loginUtils';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const user = loginUtils.getUser();
    const isEnabled = rest.roles && user && user.roles && rest.roles.find(role => user.roles.includes(role));
    return (<Route {...rest} render={props => (
        user && isEnabled ? <Component {...props} />
            : <Redirect to={{ pathname: routes.login.base, state: { from: props.location } }} />
    )} />);
};

export default PrivateRoute;