import React from 'react';
import {connect} from 'react-redux';

const Maintenance = props => {
  return (
    <div>
      <a href={'/api/v1/audit/processing/start'} target='_blank' rel="noopener noreferrer">/api/v1/audit/processing/start</a>
      <br />
      <a href={'/api/v1/audit/processing/stop'} target='_blank' rel="noopener noreferrer">/api/v1/audit/processing/stop</a>
      <br />
      <a href={'/api/v1/audit/processing/status'} target='_blank' rel="noopener noreferrer">/api/v1/audit/processing/status</a>
      <br />
      <a href={'/api/v1/audit/processing/gc'} target='_blank' rel="noopener noreferrer">/api/v1/audit/processing/gc</a>
    </div>
  );
};

function mapStateToProps(state) {
  const {loading, triggerResponse, operations, paginationConfig} = state.importPOSData;
  return {loading, triggerResponse, operations, paginationConfig};
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((Maintenance));