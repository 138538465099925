import * as actionNames from './actionNames';
import * as loginUtils from '../common/loginUtils';
import {handleError, getArrayFromRequest} from '../common/serverUtils';
import axios from 'axios';
import * as appActionNames from '../app/actionNames';
import {LOGIN_ENDPOINT, ROLES_ENDPOINT} from '../endpoints';

const customerCode = 'string';

export const login = (state, redirectCallback) => {
  const {username, password} = state;
  return async dispatch => {
    dispatch({type: actionNames.LOGIN_REQUEST});
    try {
      const response = await axios.post(LOGIN_ENDPOINT, [{customerCode, username, password}]);
      loginUtils.rememberUser(response.data);
      redirectCallback(response.data);
      dispatch({type: actionNames.LOGIN_SUCCESS, user: response.data});
    } catch (error) {
      dispatch({
        type: actionNames.LOGIN_FAILURE,
        error: error.response
      });
    };
  };
};

/* Used also for authorization on the app init */
export const getRoles = () => {
  return async dispatch => {
    try {
      const response = await axios.get(ROLES_ENDPOINT);
      dispatch({
        type: appActionNames.ROLES_LOADED,
        roles: getArrayFromRequest(response, 'roleList')
      });
    } catch (error) {
      handleError(error, dispatch);
    };
  };
};

export const logout = redirectCallback => {
  return async dispatch => {
    dispatch({type: actionNames.LOGIN_LOGOUT});
    try {
      await axios.delete(LOGIN_ENDPOINT);
      loginUtils.forgetUser();
      redirectCallback();
      dispatch({type: actionNames.LOGIN_SUCCESS});
    } catch (error) {
      console.error(error);
    };
  }
}

export const resetError = () => {
  return dispatch => {
    dispatch({type: actionNames.LOGIN_RESET_ERROR});
  };
};
