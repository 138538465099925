const formMaxWidth = '400px';

export default {
  form: {
    maxWidth: formMaxWidth,
    marginTop: '20px'
  },
  wideForm: {
    maxWidth: '800px'
  },
  input: {
    maxWidth: formMaxWidth,
    display: 'block',
    width: '100%'
  },
  tabs: {
    margin: '0 0 30px 0'
  },
  smallVerticalPadding: {
    paddingTop: '2px',
    paddingBottom: '4px'
  },
  noMargin: {
    margin: 0
  },
  smallTopMargin: {
    marginTop: '15px'
  },
  formTableRow: {
    height: '28px'
  }
};