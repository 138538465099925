import * as actionNames from './actionNames';
import axios from 'axios';
import * as serverUtils from '../../common/serverUtils';
import {SITES_ENDPOINT, SUBCATEGORIES_ENDPOINT, CUSTOMERS_ENDPOINT, CATEGORIES_ENDPOINT} from '../../endpoints';
import {validate} from './validations';

export const loadResources = id => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    try {
      const siteResponse = id ? await axios.get(`${SITES_ENDPOINT}/${id}`) : null;
      const subcategoriesResponse = await axios.get(SUBCATEGORIES_ENDPOINT);
      const categoriesResponse = await axios.get(CATEGORIES_ENDPOINT);
      const customersResponse = await axios.get(CUSTOMERS_ENDPOINT);
      dispatch({
        type: actionNames.LOADED_RESOURCES,
        site: siteResponse ? siteResponse.data : null,
        subcategories: serverUtils.getArrayFromRequest(subcategoriesResponse, 'subcategoryResourceList'),
        categories: serverUtils.getArrayFromRequest(categoriesResponse, 'categoryResourceList'),
        customers: serverUtils.getArrayFromRequest(customersResponse, 'customerResourceList')
      });
    } catch (error) {
      serverUtils.handleError(error, dispatch);
    };
  };
};

export const save = (resource, id, redirectCallback) => {
  const route = id ? `${SITES_ENDPOINT}/${id}` : SITES_ENDPOINT;
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    resource.subcategories = resource.subcategories || [];
    const {valid, errors} = validate(resource);
    if (!valid) {
      return dispatch({type: actionNames.ERROR, errors});
    }
    try {
      await axios.post(route, resource);
      redirectCallback();
      dispatch({type: actionNames.SAVED});
    } catch (error) {
      dispatch({
        type: actionNames.ERROR,
        errors: [error.message]
      });
    };
  };
};

export const remove = (id, redirectCallback) => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    try {
      await axios.delete(`${SITES_ENDPOINT}/${id}`);
      redirectCallback();
      dispatch({type: actionNames.REMOVED});
    } catch (error) {
      dispatch({
        type: actionNames.ERROR,
        error: error.message
      });
    };
  };
};

export const resetError = () => {
  return dispatch => {
    dispatch({type: actionNames.RESET_ERROR});
  };
};

export const changeTab = tab => dispatch => {
  dispatch({type: actionNames.CHANGE_TAB, tab});
};