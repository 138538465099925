import {isBefore, format} from 'date-fns/esm';

export const getNearestMarge = marges => {
  let lastMarge = null;
  let lastValidFrom = null;
  marges.forEach(margeElement => {
    const {marge, validFrom} = margeElement;
    if (isBefore(new Date(validFrom), new Date()) && (!lastValidFrom || validFrom >= lastValidFrom)) {
      lastMarge = marge;
      lastValidFrom = validFrom;
    }
  });
  return lastMarge || '';
};

export const findSiteSubcategoryById = (id, siteSubcategories) => {
  return siteSubcategories.find(s => Number(s.subcategoryId) === id);
};

export const getSiteSubcategoryMarge = (site, id) => {
  const siteSubcategories = site && site.subcategories ? site.subcategories : [];
  const siteSubcategory = findSiteSubcategoryById(id, siteSubcategories);
  return siteSubcategory && siteSubcategory.marges ? getNearestMarge(siteSubcategory.marges) : '';
};

export const getMargeValueByField = (field, result) => {
  return field === 'marge' ?
    result.target.value :
    format(result, 'yyyy-MM-dd');
}; 

export const sortByCategory = (a, b) => {
  if(a.category.toLowerCase() < b.category.toLowerCase()) { return -1; }
  if(a.category.toLowerCase() > b.category.toLowerCase()) { return 1; }
  return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
};