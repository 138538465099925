import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import {routes} from '../routes';
import {t} from '../common/translationUtils';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PeasAlert from '../common/components/forms/PeasAlert';
import PeasLoading from '../common/components/forms/PeasLoading';
import SectionTitle from '../common/components/layout/SectionTitle';
import ActiveSelect from '../common/components/list/ActiveSelect';
import * as actions from './actions';
import * as listUtils from '../common/listUtils';
import {getUser, roles} from '../common/loginUtils';
import {sortByName} from '../common/sortUtils';
import tableStyles from '../common/styles/tableStyles';
import CustomersTableBody from './CustomersTableBody';

const Customers = props => {
  const routeConfig = {
    backRoute: routes.adminDashboard.base,
    mainRoute: routes.customers
  };

  useEffect(init, []);

  function init() {
    load(props.activeOption);
  };

  const handleActiveOptionChange = event => {
    const {value} = event.target;
    props.actions.changeActiveOption(value);
    load(value);
  };

  const load = activeOption => {
    const user = getUser();
    if (props.reportsMode && !user.roles.includes(roles.ADMIN)) {
      props.actions.loadUserCustomers(user.userId, activeOption);
    } else {
      props.actions.loadAllCustomers(activeOption);
    }
  };

  const {reportsMode, loading, customers, classes, location} = props;
  const displayCustomers = customers && customers.length > 0;
  const isSuccess = new URLSearchParams(location.search).get('success');

  return (loading ? <PeasLoading/> :
    <div>
      <SectionTitle
        label={t('common.customers')}
        buttons={reportsMode ? null : listUtils.getListButtons(routeConfig, props)}/>
      <PeasAlert
          variant="success"
          open={isSuccess}
          onClose={listUtils.removeSuccessMessage(routeConfig, props)}
          message={t('common.message.success')}
        />
      <ActiveSelect
        value={props.activeOption}
        onChange={handleActiveOptionChange}/>
      <div className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>{t('common.number')}</TableCell>
              <TableCell className={classes.tableHeader}>{t('common.name')}</TableCell>
              <TableCell className={classes.tableHeader}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayCustomers &&
              <CustomersTableBody 
                customers={customers.sort(sortByName('name'))}
                reportsMode={reportsMode}
                history={props.history}
                routeConfig={routeConfig}/>
            }
          </TableBody>
        </Table>
        {!displayCustomers && <Typography variant="body1" className={classes.tableNoEntries}>
          {t('customers.message.empty')}
        </Typography>}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const {loading, customers, activeOption} = state.customers;
  return {loading, customers, activeOption};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(tableStyles)(Customers));