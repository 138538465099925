import React, {useState, useEffect} from 'react';
import {useDebounce} from 'use-debounce';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {format} from 'date-fns/esm';
import {t} from '../common/translationUtils';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PeasButton from '../common/components/forms/PeasButton';
import SectionTitle from '../common/components/layout/SectionTitle';
import PeasTable from '../common/components/list/PeasTable';
import PeasTableCell from '../common/components/list/PeasTableCell';
import PeasSearch from '../common/components/list/PeasSearch';
import {sortByName} from '../common/sortUtils';
import {getUser, roles} from '../common/loginUtils';
import * as actions from './actions';
import MonthlyReportsCustomers from './MonthlyReportsCustomers';
import tableStyles from '../common/styles/tableStyles';
import styles from './styles';

const MonthlyReports = props => {
  const user = getUser();
  const isAdmin = user.roles.includes(roles.ADMIN);
  const {reports, customers, synchronized, loading, actions, classes} = props;
  const [nameFilter] = useDebounce(props.nameFilter, 500);
  const [customerNumber, setCustomerNumber] = useState(null);
  const filteredReports = reports.filter(report => {
    return !customerNumber || report.customerNumber === customerNumber;
  });

  useEffect(() => {
    if (!loading) {
      const userId = isAdmin ? null : user.userId;
      actions.loadReports(nameFilter, userId);
    }
  }, [nameFilter, synchronized]);

  const synchronizeButton = isAdmin ? (
    <PeasButton
      label={t('common.button.synchronize')}
      onClick={() => actions.synchronize()}/>
  ) : null;

  const handleCustomerChange = newCustomerNumber => {
    const value = newCustomerNumber === customerNumber ? null : newCustomerNumber;
    setCustomerNumber(value);
  };

  return (
    <div>
      <SectionTitle
        label={t('app.monthlyReports')}
        buttons={[synchronizeButton]}/>
      <PeasSearch
        value={props.nameFilter}
        placeholder={t('monthlyReports.label.searchName')}
        handleChange={e => actions.changeNameFilter(e.target.value)}/>
      <Grid container spacing={24}>
        <Grid item xs={9}>
          <Card className={classes.card}>
            <CardContent>
              <PeasTable
                list={filteredReports}
                loading={loading}
                emptyText={t('monthlyReports.message.empty')}>
                <TableHead>
                  <TableRow>
                    <PeasTableCell header label={t('common.name')}/>
                    <PeasTableCell header label={t('common.modified')}/>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredReports.sort(sortByName('name', 'DESC')).map(report => (
                    <TableRow
                      key={report.id}
                      className={classes.clickableRow}
                      onClick={() => actions.loadReport(report.id, report.name)}>
                      <PeasTableCell width="60%" component="th" scope="row" value={report.name}/>
                      <PeasTableCell value={format(new Date(report.modified), 'dd/MM/yyyy HH:mm:ss')}/>
                    </TableRow>
                  ))}
                </TableBody>
              </PeasTable>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={classes.card}>
            <CardContent>
              <MonthlyReportsCustomers
                customers={customers}
                customerNumber={customerNumber}
                onCustomerClick={handleCustomerChange}/>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

function mapStateToProps(state) {
  const {loading, reports, customers, synchronized, nameFilter} = state.monthlyReports;
  return {loading, reports, customers, synchronized, nameFilter};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles({
  ...tableStyles,
  ...styles
})(MonthlyReports));