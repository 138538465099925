export default {
  root: {
    margin: '0'
  },
  select: {
    textAlign: 'left',
    minWidth: '100px',
    background: '#f5f5f5',
    border: '1px solid #eee',
    borderRadius: '5px',
    fontSize: '0.9rem',
    '&:before, &:hover:before, &:after': {
      border: '0 !important'
    },
    '&:hover': {
      border: '1px solid #eee'
    }
  },
  selectMenu: {
    padding: '6px 10px'
  }
};