import {t} from '../common/translationUtils';
import {format} from 'date-fns/esm';

export const DEFAULT_PAGE_SIZE = 10;

export const getTriggerMessage = triggerResponse => {
  const {status} = triggerResponse;
  return status === 200 ? t('operations.message.trigger') : t('operations.error.trigger');
};

export const formatRequestDate = date => {
  return format(new Date(date), 'dd/MM/yyyy - HH:mm:ss');
};

export const getClassByStatus = (status, classes) => {
  const classNames = {
    'DONE': 'doneStatus',
    'PENDING': 'pendingStatus',
    'SEND_TO_ABACUS': 'sendStatus',
    'TO_BE_PROCESSED_BY_ABACUS_ADAPTER': 'pendingStatus',
    'PROCESSING_BY_ADAPTER': 'pendingStatus',
    'FAILED': 'failedStatus'
  };
  const statusClass = classNames[status];
  return `${classes.itemStatus} ${classes[statusClass]}`;
};

export const TABLE_ACTION = {
  SHOW_ITEMS: 'SHOW_ITEMS',
  SHOW_HISTORY: 'SHOW_HISTORY',
  RESTART: 'RESTART'
};