import * as actionNames from './actionNames';

const initialState = {
  loading: false,
  errors: [],
  saved: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case actionNames.LOADING:
    return {
      ...state,
      loading: true,
      errors: [],
      saved: false
    };
  case actionNames.SAVED:
    return {
      ...state,
      loading: false,
      saved: true
    };
  case actionNames.ERROR:
    return {
      ...state,
      loading: false,
      errors: action.errors,
      saved: false
    };
  case actionNames.RESET_ERROR:
    return {
      ...state,
      errors: [],
      saved: false
    };
  default:
    return state
  }
};
