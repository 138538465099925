import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {routes} from '../../routes';
import {t} from '../../common/translationUtils';
import {withStyles} from '@material-ui/core/styles';
import {format} from 'date-fns/esm';
import SectionTitle from '../../common/components/layout/SectionTitle';
import PeasLoading from '../../common/components/forms/PeasLoading';
import PeasAlert from '../../common/components/forms/PeasAlert';
import PeasDatePicker from '../../common/components/forms/PeasDatePicker';
import PeasMonthPicker from '../../common/components/forms/PeasMonthPicker';
import PeasTextField from '../../common/components/forms/PeasTextField';
import * as actions from './actions';
import {getFormButtons} from  '../../common/formUtils';
import formStyles from '../../common/styles/formStyles';
import {addMonths} from 'date-fns';

const CommentForm = props => {
  const [resource, setResource] = useState({});
  useEffect(init, []);
  useEffect(fillForm, [props.comment]);

  function init() {
    const {commentId} = props.match.params;
    props.actions.loadResources(commentId);
  };

  function fillForm() {
    setResource(props.comment ? props.comment : {});
  };

  const handleClose = isSuccess => {
    const {params} = props.match;
    const mainRoute = `${routes.comments.base}/${params.customerId}`;
    const route = isSuccess === true ? `${mainRoute}?success=true` : mainRoute;
    props.history.push(route);
  };

  const handleChange = field => event => {
    setResource({...resource, [field]: event.target.value});
  };

  const handlePeriodChange = event => {
    setResource({...resource, period: event.target.value});
  };

  const handleDateChange = property => value => {
    setResource({...resource, [property]: format(value, 'yyyy-MM-dd')});
  };
  
  const handleDelete = () => {
    props.actions.remove(props.match.params.commentId, () => {
      handleClose(true);
    });
  };

  const handleSubmit = () => {
    const {customerId, commentId} = props.match.params;
    props.actions.save(resource, customerId, commentId, () => {
      handleClose(true);
    });
  };

  const {classes, match, loading, errors} = props;
  const {commentId} = match.params;
  const title = match.params.commentId ? t('comment.title.edit') : t('comment.title.create');
  const buttons = getFormButtons(handleClose, commentId ? handleDelete : null, handleSubmit);

  return (loading ? <PeasLoading/> :
    <div>
      <SectionTitle label={title} buttons={buttons}/>
      <form className={`${classes.form} ${classes.wideForm}`}>
        <PeasAlert
          multiple
          variant="error"
          open={errors !== null && errors.length > 0}
          onClose={props.actions.resetError}
          messages={errors}/>
        <PeasMonthPicker
          required
          className={classes.input}
          value={resource.period || ''}
          onChange={handlePeriodChange}
          label={t('comment.label.period')}
          name="period"/>
        <PeasDatePicker
          required
          className={classes.input}
          minDate={addMonths(resource.period || '', 1)}
          label={t('comment.label.completionDate')}
          value={resource.completionDate || null}
          onChange={handleDateChange('completionDate')}
          disabled={!resource.period}/>
        <PeasDatePicker
          className={classes.input}
          minDate={addMonths(resource.period || '', 1)}
          label={t('comment.label.lockDate')}
          value={resource.lockDate || null}
          onChange={handleDateChange('lockDate')}
          disabled={!resource.period}/>
        <PeasTextField
          required
          multiline
          rows="16"
          id="notes"
          label={t('common.notes')}
          value={resource.notes || ''}
          onChange={handleChange('notes')}
        />
      </form>
    </div>
  );
};

function mapStateToProps(state) {
  const {loading, comment, errors} = state.commentForm;
  return {
    loading, comment, errors
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(formStyles)(CommentForm));