import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const PeasCheckbox = props => (
  <FormGroup row>
    <FormControlLabel
      control={
        <Checkbox
          checked={props.checked}
          onChange={props.onChange}
          value={props.value}
          color="primary"
        />
      }
      label={props.label}
    />
  </FormGroup>
);

export default PeasCheckbox;