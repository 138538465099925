import React, { Component } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import {HashRouter as Router} from 'react-router-dom';
import {withStyles, createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {IntlProvider, addLocaleData} from 'react-intl';
import locale_en from 'react-intl/locale-data/en';
import locale_de from 'react-intl/locale-data/de';
import MainMenu from './common/components/layout/MainMenu';
import PeasAlert from './common/components/forms/PeasAlert';
import PeasLoading from './common/components/forms/PeasLoading';
import {routing} from './routes';
import {getUser} from './common/loginUtils';
import * as loginActions from './login/actions';
import * as actions from './app/actions';

addLocaleData([...locale_en, ...locale_de]);

const styles = {
  viewContainer: {
    margin: '0 auto',
    padding: '50px 25px 80px 25px',
    maxWidth: '1170px'
  }
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ed891c',
      dark: '#ca7519',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#cc3131'
    }
  },
  typography: {
    useNextVariants: true
  }
});


class App extends Component {

  componentDidMount() {
    const user = getUser();
    if(user) {
      this.props.loginActions.getRoles();
    }
    this.props.actions.setLanguage();
  }

  render() {
    const {classes, error, lang, translations} = this.props;

    if(!translations) {
      return <PeasLoading/>;
    }

    return (
      <div>
        <IntlProvider locale={lang}>
          <MuiThemeProvider theme={theme}>
            <Router>
              <div>
                <MainMenu/>
                <PeasAlert
                  variant="error"
                  open={error !== null}
                  message={error}/>
                <div className={classes.viewContainer}>
                  {routing}
                </div>
              </div>
            </Router>
          </MuiThemeProvider>
        </IntlProvider>
      </div>
    );
  }
};

function mapStateToProps(state) {
  const {error, lang, translations} = state.app;
  return {error, lang, translations};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    loginActions: bindActionCreators(loginActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(App));
