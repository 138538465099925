import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {t} from '../../common/translationUtils';
import {routes} from '../../routes';
import {withStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SectionTitle from '../../common/components/layout/SectionTitle';
import PeasLoading from '../../common/components/forms/PeasLoading';
import PeasAlert from '../../common/components/forms/PeasAlert';
import * as actions from './actions';
import {getFormButtons, getEmptyActiveResource} from  '../../common/formUtils';
import formStyles from '../../common/styles/formStyles';
import SubcategoryBaseData from './SubcategoryBaseData';
import SubcategoryAccounts from './SubcategoryAccounts';

const SubcategoryForm = props => {
  const [resource, setResource] = useState(getEmptyActiveResource(props, 'WITH_TRANSLATIONS'));
  useEffect(init, []);
  useEffect(fillForm, [props.subcategory]);

  function init() {
    const {id} = props.match.params;
    props.actions.loadResources(id);
    props.actions.changeTab(0);
  };

  function fillForm() {
    setResource(props.subcategory ? props.subcategory : getEmptyActiveResource(props, 'WITH_TRANSLATIONS'));
  };

  const handleClose = withSuccess => {
    const route = withSuccess === true ?
      `${routes.subcategories.base}?success=true` :
      routes.subcategories.base;
    props.history.push(route);
  };

  const handleChange = field => event => {
    setResource({...resource, [field]: event.target.value});
  };

  const handleTranslatedNameChange = field => translations => {
    setResource({...resource, [field]: translations});
  };
  
  const handleActiveChange = event => {
    setResource({...resource, active: event.target.checked});
  };

  const handleSelectChange = field => event => {
    const {value} = event.target;
    setResource({...resource, [field]: value});
  };

  const handleTabChange = (event, tab) => {
    props.actions.changeTab(tab);
  };

  const handleChangeVatRates = vatRates => {
    setResource({...resource, accounts: vatRates});
  };

  const handleDelete = () => {
    props.actions.remove(props.match.params.id, () => {
      handleClose(true);
    });
  };

  const handleSubmit = () => {
    props.actions.save(resource, props.match.params.id, () => {
      handleClose(true);
    });
  };

  const {classes, errors, subcategory, categories, vatRates, match, loading, tab} = props;
  const title = match.params.id ? t('subcategory.title.edit') : t('subcategory.title.create');
  const deleteFunc = match.params.id ? handleDelete : null;
  const buttons = getFormButtons(handleClose, deleteFunc, handleSubmit);

  return (loading ? <PeasLoading/> :
    <div>
      <SectionTitle label={title} buttons={buttons}/>
      <form>
        <PeasAlert
          multiple
          variant="error"
          open={errors !== null && errors.length > 0}
          onClose={props.actions.resetError}
          messages={errors}/>
        <Tabs value={tab} onChange={handleTabChange} textColor="primary" indicatorColor="primary" className={classes.tabs}>
          <Tab label={t('subcategory.baseData')}/>
          <Tab label={t('subcategory.accounts')}/>
        </Tabs>
        <SubcategoryBaseData
          visible={tab === 0}
          resource={resource}
          categories={categories}
          formClass={classes.form}
          handleActiveChange={handleActiveChange}
          handleSelectChange={handleSelectChange}
          handleTranslatedNameChange={handleTranslatedNameChange}
          handleChange={handleChange}/>
        <SubcategoryAccounts
          visible={tab === 1}
          vatRates={vatRates}
          subcategory={subcategory}
          onChange={handleChangeVatRates}/>
      </form>
    </div>
  );
};

function mapStateToProps(state) {
  const {loading, subcategory, categories, vatRates, errors, tab} = state.subcategoryForm;
  return {
    loading, subcategory, categories, vatRates, errors, tab
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(formStyles)(SubcategoryForm));