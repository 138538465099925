import React from 'react';
import {t} from '../../common/translationUtils';
import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import filterStyles from '../../common/components/list/filterStyles';

const ImportCreditCardStatusSelect = props => {
  const options = [
    {value: 'ALL', name: t('activeSelect.all')},
    {value: 'PENDING', name: t('operations.pending')},
    {value: 'SEND_TO_ABACUS', name: t('operations.send')},
    {value: 'FAILED', name: t('operations.failed')},
    {value: 'DONE', name: t('operations.completed')}
  ];
  return (
    <FormControl className={props.classes.root}>
      <Select
        className={props.classes.select}
        classes={{selectMenu: props.classes.selectMenu}}
        value={props.value}
        onChange={props.onChange}
        inputProps={{
          name: 'import-status',
          id: `import-status-simple`,
        }}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default withStyles(filterStyles)(ImportCreditCardStatusSelect);