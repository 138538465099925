import {t} from '../common/translationUtils';
import {createValidation, validateFields} from '../common/validationsUtils';

const fields = {
  'password': createValidation('changePassword.label.currentPassword', 'password', true),
  'newPassword': createValidation('changePassword.label.newPassword', 'password', true),
  'newPassword2': createValidation('changePassword.label.confirmPassword', 'password', true)
}

const errorMessages = {
  NEW_PASSWORD: 'changePassword.error.newPassword'
};

export const validate = resource => {
  const {errors} = validateFields(fields, resource);
  const {newPassword, newPassword2} = resource;
  if(newPassword && newPassword2 && newPassword !== newPassword2) {
    errors.push(t(errorMessages.NEW_PASSWORD));
  }
  return {valid: errors.length === 0, errors};
};