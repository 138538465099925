const defaultMessages = {
  'common.active': 'Active',
  'common.category': 'Category',
  'common.categories': 'Categories',
  'common.code': 'Code',
  'common.costCentre': 'Cost centre',
  'common.customer': 'Customer',
  'common.customers': 'Customers',
  'common.dataImportExport': 'Data import/export',
  'common.email': 'E-mail address',
  'common.id': 'ID',
  'common.importPOS': 'Import POS data',
  'common.importKredi': 'Import Kredi data',
  'common.importCreditCard': 'Import Credit Card data',
  'common.interfaces': 'Interfaces',
  'common.items': 'Items',
  'common.language': 'Language',
  'common.language.de': 'German',
  'common.language.en': 'English',
  'common.language.fr': 'French',
  'common.language.it': 'Italian',
  'common.modified': 'Modified',
  'common.name': 'Name',
  'common.no': 'No',
  'common.notes': 'Notes',
  'common.number': 'Number',
  'common.password': 'Password',
  'common.payload': 'Payload',
  'common.rate': 'Rate',
  'common.reasonCodes': 'Reason codes',
  'common.role': 'Role',
  'common.search': 'Search',
  'common.settings': 'Settings',
  'common.sites': 'Sites',
  'common.status': 'Status',
  'common.subcategories': 'Subcategories',
  'common.subcategory': 'Subcategory',
  'common.table.empty': 'There are no elements.',
  'common.soldTo': 'Ship To',
  'common.shipTo': 'Sold To',
  'common.time': 'Time',
  'common.user': 'User',
  'common.users': 'Users',
  'common.username': 'Username',
  'common.validFrom': 'Valid from',
  'common.vatTypes': 'VAT types',
  'common.vatRates': 'VAT rates',
  'common.button.assign': 'Assign',
  'common.button.back': 'Back',
  'common.button.cancel': 'Cancel',
  'common.button.create': 'Create',
  'common.button.delete': 'Delete',
  'common.button.process': 'Process',
  'common.button.save': 'Save',
  'common.button.print': 'Print',
  'common.button.show': 'Show',
  'common.button.synchronize': 'Synchronize',
  'common.error.email': 'You should provide valid e-mail',
  'common.error.empty': '{field} cannot be empty.',
  'common.message.success': 'Operation has been completed successfully.',
  'app.reports': 'Reporting',
  'app.administration': 'Administration',
  'app.interfaceServices': 'Interface services',
  'app.monthlyReports': 'Monthly reports',
  'activeSelect.all': 'All',
  'activeSelect.active': 'Active',
  'activeSelect.inactive': 'Inactive',
  'category.title.create': 'Create new category',
  'category.title.edit': 'Edit category',
  'categories.message.empty': 'There are not any existing categories.',
  'changePassword.title': 'Change password',
  'changePassword.label.confirmPassword': 'Repeat new password',
  'changePassword.label.currentPassword': 'Current password',
  'changePassword.label.newPassword': 'New password',
  'changePassword.error.empty': 'Password cannot be empty.',
  'changePassword.error.newPassword': 'Repeat the same password as the new pasword.',
  'changePassword.message.empty': 'Password cannot be empty.',
  'comment.title.create': 'Create new comment',
  'comment.title.edit': 'Edit comment',
  'comment.label.completionDate': 'Final completion date',
  'comment.label.createDate': 'Create date',
  'comment.label.lastChangeDate': 'Last change date',
  'comment.label.lockDate': 'Lock date',
  'comment.label.period': 'Period',
  'comments.title': 'Comments consultant',
  'comment.error.notesLength': 'Notes should have at least 20 characters.',
  'comment.error.completionDate': 'Completion date cannot be before period.',
  'comments.message.empty': 'There are not any existing comments.',
  'customer.title.create': 'Create new customer',
  'customer.title.edit': 'Edit customer',
  'customer.label.clusterId': 'Cluster ID',
  'customer.label.city': 'City',
  'customer.label.country': 'Country',
  'customer.label.legalName': 'Legal name',
  'customer.label.organisationId': 'Organisation ID',
  'customer.label.shellCreditorNo': 'Shell creditor no',
  'customer.label.street': 'Street',
  'customer.label.transferAccount': 'Transfer account',
  'customer.label.vatNumber': 'VAT number',
  'customer.label.zip': 'Postal code',
  'customers.message.empty': 'There are not any existing customers.',
  'importKredi.item.title': 'Items (operation ID = {id})',
  'importKredi.history.title': 'History (item ID = {id})',
  'importKredi.column.filename': 'Filename',
  'importKrediItem.table.documentId': 'Document Id',
  'importKrediItem.table.clusterId': 'Cluster Id',
  'importKrediItem.table.customerName': 'Customer Name',
  'importKrediItem.table.invoiceDate': 'Invoice Date',
  'importKrediItem.table.siteId': 'Site Id',
  'importPOS.item.title': 'Items (operation ID = {id})',
  'importPOS.history.title': 'History (item ID = {id})',
  'login.error.failed': 'Login has failed. Try again.',
  'login.submit': 'Login',
  'monthlyReports.label.searchName': 'Search name...',
  'monthlyReports.message.empty': 'There are not any existing monthly reports.',
  'operations.items': 'POS data items',
  'operations.completed': 'Completed',
  'operations.duplicated': 'Duplicated',
  'operations.pending': 'Pending',
  'operations.send': 'Send',
  'operations.failed': 'Failed',
  'operations.failedSchemaValidation': 'Failed Schema Validation',
  'operations.label.requestPayload': 'Request payload',
  'operations.label.responsePayload': 'Response payload',
  'operations.label.searchSiteName': 'Search site name..',
  'operations.label.searchSiteId': 'Search site id..',
  'operations.label.siteName': 'Site name',
  'operations.label.businessDayDate': 'Business date',
  'operations.tooltip.restart': 'Restart',
  'operations.tooltip.showItems': 'Show items',
  'operations.tooltip.showHistory': 'Show history',
  'operations.message.empty': 'There are not any existing operations.',
  'operations.message.trigger': 'Item successfully restarted.',
  'operations.error.trigger': 'Item restarted with errors.',
  'product.title.create': 'Create new product',
  'product.title.edit': 'Edit product',
  'product.label.accountNo': 'Account number',
  'product.label.credit': 'Credit',
  'product.label.debit': 'Debit',
  'product.label.debitCredit': 'Debit / credit',
  'product.label.costCentre': 'Cost centre',
  'products.message.empty': 'There are not any existing subcategories.',
  'reasonCode.title.create': 'Create new reason code',
  'reasonCode.title.edit': 'Edit reason code',
  'reasonCode.label.accountNo': 'Account number',
  'reasonCode.label.credit': 'Credit',
  'reasonCode.label.debit': 'Debit',
  'reasonCode.label.debitCredit': 'Debit / credit',
  'reasonCode.label.costCentre': 'Cost centre',
  'reasonCode.label.code': 'Code',
  'reasonCodes.message.empty': 'There are not any existing reason codes.',
  'site.baseData': 'Base data',
  'site.title.create': 'Create new site',
  'site.title.edit': 'Edit site',
  'site.label.siteId': 'Site ID',
  'site.label.shippedToId': 'Shipped to ID',
  'site.label.siteOrder': 'Site order',
  'site.subcategories.marges': 'Subcategory {subcategory}: Marges',
  'sites.message.empty': 'There are not any existing sites.',
  'subcategory.account': 'Account',
  'subcategory.accounts': 'Accounts',
  'subcategory.baseData': 'Base data',
  'subcategory.title.create': 'Create new subcategory',
  'subcategory.title.edit': 'Edit subcategory',
  'subcategory.label.accountNo': 'Account number',
  'subcategory.label.costAccount': 'Cost account',
  'subcategory.label.marge': 'Marge',
  'subcategory.label.stockAccount': 'Stock account',
  'subcategory.label.taxRate': 'Rate',
  'subcategory.error.accounts': 'Values in accounts should be numbers.',
  'subcategories.message.empty': 'There are not any existing subcategories.',
  'subcategories.vatRates.assign': 'Assign VAT rates',
  'user.title.create': 'Create new user',
  'user.title.edit': 'Edit user',
  'user.label.firstname': 'First name',
  'user.label.lastname': 'Last name',
  'user.label.loginname': 'Login name',
  'user.label.username': 'User name',
  'user.error.emptyRole': 'You should choose at least one role.',
  'users.message.empty': 'There are not any existing users.',
  'users.message.saved': 'User successfully saved.',
  'vatRates.message.empty': 'There are not any existing vat rates.',
};

const fillMessageWithFields = (message, params) => {
  if (params) {
    for (const param in params) {
      const paramString = `{${param}}`;
      const startIndex = message.search(`{${param}}`);
      const endIndex = startIndex + paramString.length;
      if (startIndex >= 0) {
        message = message.substring(0, startIndex) + params[param] + message.substring(endIndex, message.length);
      }
    }
  }
  return message;
};

export const t = (key, params) => {
  const translationsItem = localStorage.getItem('translations');
  const messages = translationsItem ? JSON.parse(translationsItem) : null;
  if (messages && !messages[key]) {
    console.warn(`PEAS: There is no translation for: ${key}`);
  }
  if (messages && messages[key]) {
    return fillMessageWithFields(messages[key], params);
  }
  const fallbackMessage = defaultMessages[key];
  return fallbackMessage ? fillMessageWithFields(fallbackMessage, params) : key;
};
