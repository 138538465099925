import * as actionNames from './actionNames';
import axios from 'axios';
import {handleError, getArrayFromRequest} from '../../common/serverUtils';
import {OPERATIONS_ENDPOINT} from '../../endpoints';
import {getFilters, getEqFilter, getPageFilter, getSizeFilter, addFiltersToUrl} from '../../common/filterUtils';
import {DEFAULT_PAGE_SIZE} from '../selectors';

export const loadItems = (filters, page, params) => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    const status = filters.status === 'ALL' ? null : filters.status;
    const filterQuery = getFilters([
      getEqFilter('status', status),
      getPageFilter(page),
      getSizeFilter(DEFAULT_PAGE_SIZE)
    ]);

    try {
      const encodedId = encodeURIComponent(params.requestId);
      const link = `${OPERATIONS_ENDPOINT}/${encodedId}/items`;
      const response = await axios.get(addFiltersToUrl(link, filterQuery));
      dispatch({
        type: actionNames.LOADED, 
        items: getArrayFromRequest(response, 'auditItemResourceList'),
        paginationConfig: response.data ? response.data.page : null
      });
    } catch (error) {
      handleError(error, dispatch);
    };
  };
};

export const triggerItem = (operationId, itemId) => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    try {
      const encodedId = encodeURIComponent(operationId);
      const link = `${OPERATIONS_ENDPOINT}/${encodedId}/items/${itemId}/restart`;
      const response = await axios.get(link);
      dispatch({
        type: actionNames.REQUEST_TRIGGERED, 
        triggerResponse: response
      });
    } catch (error) {
      handleError(error, dispatch);
    };
  };
};

export const resetTriggerMessage = () => {
  return async dispatch => {
    dispatch({
      type: actionNames.TRIGGER_MESSAGE_REMOVED, 
      triggerResponse: null
    });
  };
};