import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {format, parse} from 'date-fns/esm';
import {withStyles} from '@material-ui/core/styles';
import {routes} from '../routes';
import {t} from '../common/translationUtils';
import Edit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PeasButton from '../common/components/forms/PeasButton';
import PeasLoading from '../common/components/forms/PeasLoading';
import PeasAlert from '../common/components/forms/PeasAlert';
import PeasYearStepper from '../common/components/forms/PeasYearStepper';
import SectionTitle from '../common/components/layout/SectionTitle';
import * as actions from './actions';
import {sortByDate} from '../common/sortUtils';
import tableStyles from '../common/styles/tableStyles';

const Comments = props => {
  const [year, setYear] = useState(new Date().getFullYear());
  const routeConfig = {
    backRoute: routes.reports.base,
    mainRoute: routes.comments
  };
  useEffect(init, []);

  function init() {
    const {customerId} = props.match.params;
    props.actions.loadComments(customerId);
  }

  const getUsername = id => {
    const user = props.users.find(user => user.id === id);
    return user ? `${user.firstname} ${user.lastname}` : '';
  };

  const getFilteredComments = year => {
    const {comments} = props;
    return comments ? comments.filter(comment => {
      return comment.period.includes(year);
    }).sort(sortByDate('period')) : [];
  };

  const handleYearChange = offset => () => {
    setYear(year + offset);
  };

  const handleClose = () => {
    props.history.push(routeConfig.backRoute);
  };

  const handleEdit = commentId => () => {
    const {customerId} = props.match.params;
    props.history.push(`${routeConfig.mainRoute.form}/${customerId}/${commentId}`);
  };

  const handleCreate = () => {
    const {customerId} = props.match.params;
    props.history.push(`${routeConfig.mainRoute.form}/${customerId}`);
  };

  const removeSuccessMessage = () => {
    const {customerId} = props.match.params;
    props.history.push(`${routeConfig.mainRoute.base}/${customerId}`);
  };

  const {loading, classes, location, customer} = props;
  const filteredComments = getFilteredComments(year);
  const displayComments = filteredComments && filteredComments.length > 0;
  const isSuccess = new URLSearchParams(location.search).get('success');

  const buttons = [
    <PeasButton
      color={null}
      label={t('common.button.cancel')}
      onClick={handleClose}/>,
    <PeasButton
      label={t('common.button.create')}
      onClick={handleCreate}/>
  ];

  return (loading ? <PeasLoading/> :
    <div>
      <SectionTitle
        label={t('comments.title')}
        subtitle={customer ? customer.name : null}
        buttons={buttons}/>
      <PeasAlert
          variant="success"
          open={isSuccess}
          onClose={removeSuccessMessage}
          message={t('common.message.success')}
        />
      <div className={classes.tableContainer}>
        <PeasYearStepper year={year} onClick={handleYearChange}/>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="150px" className={classes.tableHeader}>
                {t('comment.label.period')}
              </TableCell>
              <TableCell className={classes.tableHeader}>
                {t('comment.label.completionDate')}
              </TableCell>
              <TableCell className={classes.tableHeader}>
                {t('comment.label.createDate')}
              </TableCell>
              <TableCell className={classes.tableHeader}>
                {t('comment.label.lastChangeDate')}
              </TableCell>
              <TableCell className={classes.tableHeader}>
                {t('common.user')}
              </TableCell>
              <TableCell className={classes.tableHeader}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayComments && filteredComments.map(comment => (
              <TableRow key={comment.id}>
                <TableCell component="th" scope="row">
                  {format(parse(comment.period, 'yyyy-MM-dd', new Date()), 'MMMM yyyy')}
                </TableCell>
                <TableCell>{comment.completionDate}</TableCell>
                <TableCell>{comment.createDate}</TableCell>
                <TableCell>{comment.lastChangeDate}</TableCell>
                <TableCell>{getUsername(comment.userId)}</TableCell>
                <TableCell className={classes.tableActions}>
                  <IconButton aria-label="edit" onClick={handleEdit(comment.id)}>
                    <Edit fontSize="small"/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!displayComments && <Typography variant="body1" className={classes.tableNoEntries}>
          {t('comments.message.empty')}
        </Typography>}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const {loading, comments, users, customer} = state.comments;
  return {loading, comments, users, customer};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(tableStyles)(Comments));