

import React from 'react';
import {injectIntl} from 'react-intl';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import {withStyles} from '@material-ui/core/styles';

const ALERT_DELAY = 6000;
const styles = theme => ({
  success: {backgroundColor: green[600]},
  error: {backgroundColor: theme.palette.error.dark},
  info: {backgroundColor: theme.palette.primary.dark},
  warning: {backgroundColor: amber[700]},
  paragraph: {
    margin: 0
  }
});

const PeasAlert = props => {
  const handleClose = callback => (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    if (callback) {
      callback();
    }
  };

  const getMessageFromArray = () => {
    console.log(JSON.stringify(props));
    return props.messages.map(message => {
      const label = message ? (message.props ? props.intl.formatMessage(message.props) : message) : [''];
      return (<p className={props.classes.paragraph}>
        {label[0].toUpperCase() + label.slice(1)}
      </p>);
    });
  }

  return (<Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    autoHideDuration={ALERT_DELAY}
    onClose={handleClose(props.onClose)}
    open={props.open}>
      <SnackbarContent
        className={props.classes[props.variant]}
        aria-describedby="client-snackbar"
        message={props.multiple ? getMessageFromArray() : props.message}
      />
    </Snackbar>);
};

export default injectIntl(withStyles(styles)(PeasAlert));