import React from 'react';
import TextField from '@material-ui/core/TextField';

const PeasTextField = props => (
  <TextField
    margin={props.type === 'table' ? undefined : 'normal'}
    fullWidth={props.fullWidth !== undefined ? props.fullWidth : true}
    {...props}
  />
);

export default PeasTextField;