import * as actionNames from './actionNames';

const initialState = {
  loading: false,
  subcategory: null,
  vatRates: [],
  categories: [],
  errors: [],
  tab: 0
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case actionNames.LOADING:
    return {
      ...state,
      loading: true,
      errors: []
    };
  case actionNames.CHANGE_TAB:
    return {
      ...state,
      tab: action.tab
    };
  case actionNames.LOADED_RESOURCES:
    return {
      ...state,
      loading: false,
      subcategory: action.subcategory,
      vatRates: action.vatRates,
      categories: action.categories
    };
  case actionNames.SAVED:
    return {
      ...state,
      loading: false
    };
  case actionNames.REMOVED:
    return {
      ...state,
      loading: false,
      subcategory: null
    };
  case actionNames.ERROR:
    return {
      ...state,
      loading: false,
      errors: [action.error]
    };
  case actionNames.RESET_ERROR:
    return {
      ...state,
      errors: []
    };
  default:
    return state
  }
};
