import React, {useState, useEffect} from 'react';
import {t} from '../../common/translationUtils';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Edit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import PeasButton from '../../common/components/forms/PeasButton';
import SubcategoryAccountForm from './SubcategoryAccountForm';
import tableStyles from '../../common/styles/tableStyles';

const SubcategoryAccounts = props => {
  const [accounts, setAccounts] = useState([]);
  const [editAccountData, setEditAccountData] = useState({
    index: -1,
    account: null
  });

  /* 
    Setting subcategory accounts during edit when
    subcategory is fetched from the server.
  */
  useEffect(() => {
    const {subcategory} = props;
    if (subcategory && subcategory.accounts) {
      setAccounts(subcategory.accounts);
    }
  }, [props.subcategory]);

  const handleEdit = (account, index) => () => {
    setEditAccountData({index, account});
  };

  const handleAdd = () => {
    setEditAccountData({index: -1, account: {}});
  };

  const handleCloseForm = () => {
    setEditAccountData({index: -1, account: null});
  };

  const handleDeleteAccount = () => {
    accounts.splice(editAccountData.index, 1);
    setAccounts(accounts);
    props.onChange(accounts);
  };

  const handleSaveAccount = account => {
    const updatedAccounts = [...accounts];
    if (editAccountData.index >= 0) {
      updatedAccounts[editAccountData.index] = account;
    } else {
      updatedAccounts.push(account);
    }
    setAccounts(updatedAccounts);
    props.onChange(updatedAccounts);
  };

  const {classes} = props;

  return (props.visible ? <div className={classes.tableContainer}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width="100px" className={classes.tableHeader}>{t('common.rate')}</TableCell>
          <TableCell className={classes.tableHeader}>{t('common.code')}</TableCell>
          <TableCell className={classes.tableHeader}>{t('subcategory.label.accountNo')}</TableCell>
          <TableCell className={classes.tableHeader}>{t('subcategory.label.costAccount')}</TableCell>
          <TableCell className={classes.tableHeader}>{t('subcategory.label.stockAccount')}</TableCell>
          <TableCell className={classes.tableHeader}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {accounts ? accounts.map((account, index) => (
          <TableRow key={`${index}-${account.taxRate}`}>
            <TableCell component="th" scope="row">{account.taxRate}</TableCell>
            <TableCell>{account.taxCode}</TableCell>
            <TableCell>{account.accountNo}</TableCell>
            <TableCell>{account.costAccount}</TableCell>
            <TableCell>{account.stockAccount}</TableCell>
            <TableCell className={classes.tableActions}>
              <IconButton aria-label="edit" onClick={handleEdit(account, index)}>
                <Edit fontSize="small"/>
              </IconButton>
            </TableCell>
          </TableRow>
        )) : null}
      </TableBody>
    </Table>
    <PeasButton
      className={classes.bottomButton}
      label={t('common.button.create')}
      onClick={handleAdd}/>
    <SubcategoryAccountForm
      open={Boolean(editAccountData.account)}
      account={editAccountData.account}
      index={editAccountData.index}
      handleClose={handleCloseForm}
      handleDelete={handleDeleteAccount}
      handleSubmit={handleSaveAccount}/>
  </div> : null);
};

export default withStyles(tableStyles)(SubcategoryAccounts);