import React from 'react';
import PeasTextField from '../../common/components/forms/PeasTextField';
import PeasTranslatedTextField from '../../common/components/forms/PeasTranslatedTextField';
import PeasCheckbox from '../../common/components/forms/PeasCheckbox';
import PeasSelect from '../../common/components/forms/PeasSelect';
import {t} from '../../common/translationUtils';
import {typesOptions} from "../../reasonCodes/constants";

const SubcategoryBaseData = props => {
  const {resource} = props;

  return (props.visible ? <div className={props.formClass}>
    <PeasCheckbox
      checked={resource.active || false}
      onChange={props.handleActiveChange}
      value="active"
      label={t('common.active')}/>
    <PeasTextField
      id="number"
      type="number"
      label={t('common.number')}
      value={resource.number || ''}
      onChange={props.handleChange('number')}/>
    <PeasTranslatedTextField
      id="name"
      label={t('common.name')}
      value={resource.nameTranslations || {}}
      onChange={props.handleTranslatedNameChange('nameTranslations')}
    />
    <PeasSelect
      value={resource.category || ''}
      onChange={props.handleSelectChange('category')}
      label={t('common.category')}
      options={props.categories.filter(category => category.active)}
      name="category"/>
    <PeasSelect
        value={resource.type || ''}
        onChange={props.handleSelectChange('type')}
        label={'Type'}
        options={typesOptions}
        name="type"/>
  </div> : null);
};

export default SubcategoryBaseData;