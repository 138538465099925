import {createValidation, validateFields, errorMessages} from '../../common/validationsUtils';
import {t} from '../../common/translationUtils';

const fields = {
  'number': createValidation('common.number', 'number', true),
  'name': createValidation('common.name', 'text', true),
  'shippedToId': createValidation('site.label.shippedToId', 'number', true),
  'siteOrder': createValidation('site.label.siteOrder', 'number', true),
  'costCentre': createValidation('common.costCentre', 'text', true)
}

export const validate = resource => {
  const {errors} = validateFields(fields, resource);
  const isWrongSubcategory = resource.subcategories.some(subcategory => {
    const {marges} = subcategory;
    return marges ? marges.some(marge => isNaN(marge.marge)) : false;
  });
  if (isWrongSubcategory) {
    errors.push(t(errorMessages.NUMBER_ERROR, {field: t('subcategory.label.marge')}));
  }
  return {valid: errors.length === 0, errors};
};