import React from 'react';
import {t} from '../../common/translationUtils';
import PeasTextField from '../../common/components/forms/PeasTextField';
import PeasCheckbox from '../../common/components/forms/PeasCheckbox';
import PeasSelect from '../../common/components/forms/PeasSelect';

const SiteBaseData = props => {
  const {resource, formClass, customers} = props;
  const {active, number, name, shippedToId, siteOrder, costCentre} = resource;

  return (props.visible ? <div className={formClass}>
    <PeasCheckbox
      checked={active || false}
      onChange={props.handleActiveChange}
      value="active"
      label={t('common.active')}/>
    <PeasTextField
      id="number"
      label={t('common.number')}
      value={number || ''}
      onChange={props.handleChange('number')}
    />
    <PeasTextField
      id="name"
      label={t('common.name')}
      value={name ||  ''}
      onChange={props.handleChange('name')}
    />
    <PeasTextField
      id="shippedToId"
      label={t('site.label.shippedToId')}
      value={shippedToId || ''}
      onChange={props.handleChange('shippedToId')}
    />
    <PeasTextField
      id="siteOrder"
      label={t('site.label.siteOrder')}
      value={siteOrder || ''}
      onChange={props.handleChange('siteOrder')}
    />
    <PeasTextField
      id="costCentre"
      label={t('common.costCentre')}
      value={costCentre || ''}
      onChange={props.handleChange('costCentre')}
    />
    <PeasSelect
      value={resource.customer ? resource.customer.id : ''}
      onChange={props.handleCustomerChange('customer')}
      label={t('common.customer')}
      options={customers}
      name="customer"
      />
  </div> : null);
};

export default SiteBaseData;