import React from 'react';
import {t} from '../../translationUtils';
import {Table} from '@material-ui/core';
import EmptyList from './EmptyList';
import PeasPagination from './PeasPagination';
import PeasLoading from '../forms/PeasLoading';

const PeasTable = props => {
  const {pagination, loading} = props;
  return (loading ? <PeasLoading/> : <div className={props.className}>
    <Table>
      {props.children}
    </Table>
    <EmptyList items={props.list} label={props.emptyText || t('common.table.empty')}/>
    {pagination ? <PeasPagination
      pageConfig={pagination.config}
      page={pagination.page}
      onChangePage={pagination.setPage}/> : null}
  </div>);
};

export default PeasTable;
