import * as actionNames from './actionNames';
import axios from 'axios';
import {handleError, getArrayFromRequest} from '../common/serverUtils';
import {PDF_REPORTS_ENDPOINT, CUSTOMERS_ENDPOINT, USERS_ENDPOINT} from '../endpoints';
import {getFilters, getLikeFilter, addFiltersToUrl} from '../common/filterUtils';

export const loadReports = (nameFilter, userId) => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    const filterQuery = getFilters([
      getLikeFilter('name', nameFilter)
    ]);

    try {
      const customersLink = userId ? `${USERS_ENDPOINT}/${userId}/customers` : CUSTOMERS_ENDPOINT;
      const response = await axios.get(addFiltersToUrl(PDF_REPORTS_ENDPOINT, filterQuery));
      const customersResponse = await axios.get(customersLink);
      dispatch({
        type: actionNames.LOADED,
        reports: getArrayFromRequest(response, 'reportFileResourceList'),
        customers: getArrayFromRequest(customersResponse, 'customerResourceList')
      });
    } catch (error) {
      handleError(error, dispatch);
    };
  };
};

export const loadReport = (id, name) => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    try {
      const link = `${PDF_REPORTS_ENDPOINT}/${id}`;
      window.open(link, name);
      dispatch({
        type: actionNames.REPORT_LOADED
      });
    } catch (error) {
      handleError(error, dispatch);
    };
  };
};

export const synchronize = () => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    try {
      await axios.get(`${PDF_REPORTS_ENDPOINT}/synchronize`);
      dispatch({type: actionNames.SYNCHRONIZED});
    } catch (error) {
      handleError(error, dispatch);
    };
  };
};

export const changeNameFilter = nameFilter => dispatch => {
  dispatch({type: actionNames.CHANGE_NAME_FILTER, nameFilter});
};
