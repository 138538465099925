import {parse} from 'date-fns/esm';

export const sortByDate = field => (a, b) => {
  return parse(a[field], 'yyyy-MM-dd', new Date()) - parse(b[field],  'yyyy-MM-dd', new Date());
};

export const sortByName = (field, sortType) => (a, b) => {
  const multiplicator = sortType === 'DESC' ? -1 : 1;
  if(a[field] && b[field] && a[field].toLowerCase() < b[field].toLowerCase()) { return multiplicator * -1; }
  if(a[field] && b[field] && a[field].toLowerCase() > b[field].toLowerCase()) { return multiplicator * 1; }
  return 0;
};

export const sortByNumber = field => (a, b) => {
  return a[field] - b[field];
}