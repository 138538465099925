import * as actionNames from './actionNames';

const initialState = {
  loading: false,
  site: null,
  subcategories: [],
  categories: [],
  customers: [],
  tab: 0,
  errors: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case actionNames.LOADING:
    return {
      ...state,
      loading: true,
      errors: []
    };
  case actionNames.CHANGE_TAB:
    return {
      ...state,
      tab: action.tab
    };
  case actionNames.LOADED_RESOURCES:
    return {
      ...state,
      loading: false,
      site: action.site,
      subcategories: action.subcategories,
      categories: action.categories,
      customers: action.customers
    };
  case actionNames.SAVED:
    return {
      ...state,
      loading: false,
      site: null
    };
  case actionNames.REMOVED:
    return {
      ...state,
      loading: false,
      site: null
    };
  case actionNames.ERROR:
    return {
      ...state,
      loading: false,
      errors: action.errors
    };
  case actionNames.RESET_ERROR:
    return {
      ...state,
      errors: []
    };
  default:
    return state
  }
};
