import * as actionNames from './actionNames';
import axios from 'axios';
import {handleError} from '../common/serverUtils';
import {REASON_CODES_ENDPOINT} from '../endpoints';
import {getFilters, getEqFilter} from '../common/filterUtils';

const filters = active => [
  active === 'all' ? '' : getEqFilter('active', active)
];


export const loadReasonCodes = activeOption => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    const filterQuery = getFilters(filters(activeOption));
    try {
      const response = await axios.get(`${REASON_CODES_ENDPOINT}${filterQuery}`);
      const {_embedded} = response.data;
      dispatch({type: actionNames.LOADED, reasonCodes: _embedded ? _embedded.reasonCodeResourceList : []});
    } catch (error) {
      handleError(error, dispatch);
    };
  };
};

export const changeActiveOption = activeOption => dispatch => {
  dispatch({type: actionNames.CHANGE_ACTIVE_OPTION, activeOption});
};