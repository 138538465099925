const ARRAY_NAMES = ['subcategories', 'pays', 'cards'];

export const getResourceToSave = resource => {
  const finalResource = {...resource};
  ARRAY_NAMES.forEach(arrayName => {
    if (finalResource[arrayName]) {
      for (const [key, value] of Object.entries(finalResource[arrayName])) {
        finalResource[arrayName][key] = value && value.value ? Number(value.value).toFixed(2) : undefined;
      }
    }
  });
  return finalResource;
};
