import * as actionNames from './actionNames';
import axios from 'axios';
import {handleError} from '../../common/serverUtils';
import {SUBCATEGORIES_ENDPOINT, VAT_RATES_ENDPOINT, CATEGORIES_ENDPOINT} from '../../endpoints';
import {validate} from './validations';

export const loadResources = id => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    try {
      const subcategoryResponse = id ? await axios.get(`${SUBCATEGORIES_ENDPOINT}/${id}`) : null;
      const vatRatesResponse = await axios.get(VAT_RATES_ENDPOINT);
      const categoriesResponse = await axios.get(CATEGORIES_ENDPOINT);
      const {_embedded: vatRatesEmbedded} = vatRatesResponse.data;
      const {_embedded: categoriesEmbedded} = categoriesResponse.data;
      dispatch({
        type: actionNames.LOADED_RESOURCES,
        subcategory: subcategoryResponse ? subcategoryResponse.data : null,
        vatRates: vatRatesEmbedded ? vatRatesEmbedded.vatRateList : [],
        categories: categoriesEmbedded ? categoriesEmbedded.categoryResourceList : []
      });
    } catch (error) {
      handleError(error, dispatch);
    };
  };
};

export const save = (resource, id, redirectCallback) => {
  const route = id ? `${SUBCATEGORIES_ENDPOINT}/${id}` : SUBCATEGORIES_ENDPOINT;
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    resource.accounts = resource.accounts || [];
    const {valid, errors} = validate(resource);
    if (!valid) {
      return dispatch({type: actionNames.ERROR, errors});
    }
    try {
      await axios.post(route, resource);
      redirectCallback();
      dispatch({type: actionNames.SAVED});
    } catch (error) {
      dispatch({
        type: actionNames.ERROR,
        error: error.message
      });
    };
  };
};

export const remove = (id, redirectCallback) => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    try {
      await axios.delete(`${SUBCATEGORIES_ENDPOINT}/${id}`);
      redirectCallback();
      dispatch({type: actionNames.REMOVED});
    } catch (error) {
      dispatch({
        type: actionNames.ERROR,
        error: error.message
      });
    };
  };
};

export const resetError = () => dispatch => {
  dispatch({type: actionNames.RESET_ERROR});
};

export const changeTab = tab => dispatch => {
  dispatch({type: actionNames.CHANGE_TAB, tab});
};