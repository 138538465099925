import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {routes} from '../../routes';
import {t} from '../../common/translationUtils';
import {withStyles} from '@material-ui/core/styles';
import SectionTitle from '../../common/components/layout/SectionTitle';
import PeasLoading from '../../common/components/forms/PeasLoading';
import PeasAlert from '../../common/components/forms/PeasAlert';
import PeasCheckbox from '../../common/components/forms/PeasCheckbox';
import PeasTextField from '../../common/components/forms/PeasTextField';
import PeasSelect from '../../common/components/forms/PeasSelect';
import * as actions from './actions';
import {debitCreditOptions} from '../constants';
import {getFormButtons, getEmptyActiveResource} from  '../../common/formUtils';
import formStyles from '../../common/styles/formStyles';

const ProductForm = props => {
  const [resource, setResource] = useState(getEmptyActiveResource(props));
  useEffect(init, []);
  useEffect(fillForm, [props.product]);

  function init() {
    const {id} = props.match.params;
    props.actions.loadResources(id);
  };

  function fillForm() {
    setResource(props.product ? props.product : getEmptyActiveResource(props));
  };

  const handleClose = withSuccess => {
    const route = withSuccess === true ?
      `${routes.products.base}?success=true` :
      routes.products.base;
    props.history.push(route);
  };

  const handleChange = field => event => {
    setResource({...resource, [field]: event.target.value});
  };

  const handleActiveChange = event => {
    setResource({...resource, active: event.target.checked});
  };

  const handleSelectChange = field => event => {
    const {value} = event.target;
    setResource({...resource, [field]: value});
  };

  const handleDelete = () => {
    props.actions.remove(props.match.params.id, () => {
      handleClose(true);
    });
  };

  const handleSubmit = () => {
    props.actions.save(resource, props.match.params.id, () => {
      handleClose(true);
    });
  };

  const {classes, match, loading, errors} = props;
  const title = match.params.id ? t('product.title.edit') : t('product.title.create');
  const deleteFunc = match.params.id ? handleDelete : null;
  const buttons = getFormButtons(handleClose, deleteFunc, handleSubmit);

  return (loading ? <PeasLoading/> :
    <div>
      <SectionTitle label={title} buttons={buttons}/>
      <form className={classes.form}>
        <PeasAlert
          multiple
          variant="error"
          open={errors !== null && errors.length > 0}
          onClose={props.actions.resetError}
          messages={errors}/>
        <PeasCheckbox
          checked={resource.active || false}
          onChange={handleActiveChange}
          value="active"
          label={t('common.active')}/>
        <PeasTextField
          id="name"
          label={t('common.no')}
          value={resource.number || ''}
          onChange={handleChange('number')}/>
        <PeasTextField
          id="name"
          label={t('common.name')}
          value={resource.name || ''}
          onChange={handleChange('name')}/>
         <PeasSelect
          value={resource.debitCredit || ''}
          onChange={handleSelectChange('debitCredit')}
          label={t('product.label.debitCredit')}
          options={debitCreditOptions}
          name="debitCredit"/>
        <PeasTextField
          id="accountNo"
          label={t('product.label.accountNo')}
          value={resource.accountNo !== undefined ? String(resource.accountNo) : ''}
          onChange={handleChange('accountNo')}/>
        <PeasTextField
          id="costCentre"
          label={t('product.label.costCentre')}
          value={resource.costCentre !== undefined ? String(resource.costCentre) : ''}
          onChange={handleChange('costCentre')}/>
      </form>
    </div>
  );
};

function mapStateToProps(state) {
  const {loading, product, errors} = state.productsForm;
  return {
    loading, product, errors
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(formStyles)(ProductForm));
