/*
  Hook for filtering & paging on list views.
  onChange function should be prepared for three params:
  - filters - object contains all filters important for fetching process
  - page - number of currently chosen page
  - loadParams - static variables for loading
*/
import {useState, useEffect} from 'react';
import {useDebounce} from 'use-debounce';
import {getUrlParam, setUrlParam} from '../urlUtils';

export const useList = props => {
  const [page, setPage] = useState(null);
  const [filters, setFilters] = useState(props.defaultFilters || {});
  const [debouncedFilters] = useDebounce(filters, props.debounce || 500);

  useEffect(() => {
    if (page !== null && page >= 0) {
      setUrlParam(props.history)('page', page);
      props.loader(filters, page, props.loadParams);
    }
  }, [page]);

  useEffect(() => {
    if (page === 0) {
      props.loader(filters, page, props.loadParams);
    } else {
      const pageFromUrl = getUrlParam(props.history)('page') || 0;
      setPage(page && page >= 0 ? 0 : Number(pageFromUrl));
    }
  }, [debouncedFilters]);

  /* Util object for getting and modifying filters */
  const filterer = {
    get: property => filters[property],
    set: (property, value) => setFilters({
      ...filters,
      [property]: value
    }),
    filters
  };

  /* Util object for getting and setting pagination data */
  const pagination = {
    page,
    setPage,
    config: props.paginationConfig
  };

  return [filterer, pagination];
};
