export const LOGIN_ENDPOINT = 'api/v1/auth';
export const HEALTH_ENDPOINT = 'api/v1/health';
export const ROLES_ENDPOINT = 'api/v1/roles';
export const CUSTOMERS_ENDPOINT = 'api/v1/customers';
export const USERS_ENDPOINT = 'api/v1/users';
export const COMMENTS_ENDPOINT = 'api/v1/comments';
export const SUBCATEGORIES_ENDPOINT = 'api/v1/subcategories';
export const PRODUCTS_ENDPOINT = 'api/v1/products';
export const REASON_CODES_ENDPOINT = 'api/v1/reason_codes';
export const MANUAL_IMPORTS_ENDPOINT = 'api/v1/manual_imports';
export const BOOKING_VOUCHERS_ENDPOINT = 'api/v1/booking_vouchers';
export const VAT_RATES_ENDPOINT = 'api/v1/vat_rates';
export const SITES_ENDPOINT = 'api/v1/sites';
export const CATEGORIES_ENDPOINT = 'api/v1/categories';
export const TRANSLATIONS_ENDPOINT = 'api/v1/translations';
export const OPERATIONS_ENDPOINT = 'api/v1/audit/operations';
export const KREDI_ENDPOINT = 'api/v1/audit/kredi';
export const OPERATIONS_CREDIT_CARD_ENDPOINT = 'api/v1/audit/creditcards';
export const PDF_REPORTS_ENDPOINT = 'api/v1/pdfreports';
