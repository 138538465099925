import * as actionNames from './actionNames';

const initialState = {
  loading: false,
  product: null,
  errors: [],
  tab: 0
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case actionNames.LOADING:
    return {
      ...state,
      loading: true,
      errors: []
    };
  case actionNames.LOADED_RESOURCES:
    return {
      ...state,
      loading: false,
      product: action.product
    };
  case actionNames.SAVED:
    return {
      ...state,
      loading: false
    };
  case actionNames.REMOVED:
    return {
      ...state,
      loading: false,
      product: null
    };
  case actionNames.ERROR:
    return {
      ...state,
      loading: false,
      errors: action.errors
    };
  case actionNames.RESET_ERROR:
    return {
      ...state,
      errors: []
    };
  default:
    return state
  }
};
