import * as actionNames from './actionNames';
import axios from 'axios';
import {handleError} from '../../common/serverUtils';
import {PRODUCTS_ENDPOINT} from '../../endpoints';
import {validate} from './validations';

export const loadResources = id => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    try {
      const productResponse = id ? await axios.get(`${PRODUCTS_ENDPOINT}/${id}`) : null;
      dispatch({
        type: actionNames.LOADED_RESOURCES,
        product: productResponse ? productResponse.data : null
      });
    } catch (error) {
      handleError(error, dispatch);
    };
  };
};

export const save = (resource, id, redirectCallback) => {
  const route = id ? `${PRODUCTS_ENDPOINT}/${id}` : PRODUCTS_ENDPOINT;
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    const {valid, errors} = validate(resource);
    if (!valid) {
      return dispatch({type: actionNames.ERROR, errors});
    }
    try {
      await axios.post(route, resource);
      redirectCallback();
      dispatch({type: actionNames.SAVED});
    } catch (error) {
      dispatch({
        type: actionNames.ERROR,
        errors: [error.message]
      });
    };
  };
};

export const remove = (id, redirectCallback) => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    try {
      await axios.delete(`${PRODUCTS_ENDPOINT}/${id}`);
      redirectCallback();
      dispatch({type: actionNames.REMOVED});
    } catch (error) {
      dispatch({
        type: actionNames.ERROR,
        errors: [error.message]
      });
    };
  };
};

export const resetError = () => dispatch => {
  dispatch({type: actionNames.RESET_ERROR});
};
