import React from 'react';
import {TablePagination} from '@material-ui/core';

const PeasPagination = props => {
  const {pageConfig, page, onChangePage} = props;
  return (pageConfig ?
    <TablePagination
      component="div"
      count={pageConfig.totalElements}
      rowsPerPage={pageConfig.size}
      rowsPerPageOptions={[]}
      page={page}
      onChangePage={(e, page) => onChangePage(page)}/> : null
  );
};

export default PeasPagination;
