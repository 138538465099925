import React from 'react';
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Edit from '@material-ui/icons/Edit';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import IconButton from '@material-ui/core/IconButton';
import {tabs, shouldShowTab, shouldHideMenu, getTabValue} from '../../routeUtils';
import {routes} from '../../../routes';
import {t} from '../../translationUtils';
import * as loginActions from '../../../login/actions';
import * as appActions from '../../../app/actions';
import {mainMenuStyles} from './styles';

class MainMenu extends React.Component {

  handleMenuItemChange = (event, value) => {
    const tab = tabs[value];
    this.props.history.push(tab.route);
    this.setState({route: tab.route});
  }

  handleChangePassword = () => {
    this.props.history.push(routes.changePassword.base);
  }

  handleChangeLanguage = event => {
    const {value} = event.target;
    this.props.appActions.setLanguage(value);
  }

  handleLogout = () => {
    this.props.loginActions.logout(() => {
      this.props.history.push(routes.login.base);
    });
  }

  getUserInfo = user => {
    if (!user) {
      return '';
    }
    return user.firstname && user.lastname ? `${user.firstname} ${user.lastname}` : user.username;
  }

  render() {
    const {classes, location, user, lang} = this.props;
    const route = location.pathname;
    const tabValue = getTabValue(route);
    const languages = ['en', 'de', 'fr'];

    if(shouldHideMenu(route)) {
      return null;
    }

    return (<div>
      <AppBar position="static" color="default">
        <Toolbar className={classes.toolbar}>
          <img src={process.env.PUBLIC_URL + "/shell_logo.svg"} className={classes.logo} alt="SHELL ERP logo"/>

          <Tabs
            value={tabValue >= 0 ? tabValue : tabs.length}
            onChange={this.handleMenuItemChange}
            indicatorColor="primary"
            textColor="primary">
            {tabs.map(tab => (
              <Tab key={tab.route} label={t(tab.label)} className={shouldShowTab(tab, user) ? classes.tab : classes.emptyTab}/>
            ))}
            {/* Empty tab for mismatched routes */}
            <Tab key="empty" className={classes.emptyTab}/>
          </Tabs>

          <span className={classes.editSection}>
            <Typography variant="body1" className={classes.editSectionText}>
              {this.getUserInfo(user)}
            </Typography>
            <IconButton aria-label="changePassword" onClick={this.handleChangePassword}>
              <Edit fontSize="small"/>
            </IconButton>
          </span>

          <span className={classes.languageSection}>
          <FormControl>
            <Select
              className={classes.languageSelect}
              classes={{selectMenu: classes.languageSelectMenu}}
              value={lang}
              onChange={this.handleChangeLanguage}
              inputProps={{
                name: 'language',
                id: `language-simple`,
              }}>
              {languages.map(language => (
                <MenuItem key={language} value={language}>{language}</MenuItem>
              ))}
            </Select>
          </FormControl>
          </span>

          <IconButton aria-label="logout" className={classes.logoutButton} onClick={this.handleLogout}>
            <PowerSettingsNew fontSize="small"/>
          </IconButton>

        </Toolbar>
      </AppBar>
    </div>);
  }
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
    lang: state.app.lang
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginActions: bindActionCreators(loginActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch)
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps, null, {pure: false}
)(withRouter(withStyles(mainMenuStyles)(MainMenu)));