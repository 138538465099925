import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

export default {
  statusSelectorColumn: {
    textAlign: 'right'
  },
  itemStatus: {
    display: 'inline-block',
    margin: '0 3px',
    padding: '0 6px',
    height: '20px',
    borderRadius: '20px',
    color: 'white',
    textAlign: 'center',
    lineHeight: '20px'
  },
  doneStatus: {background: green[400]},
  pendingStatus: {background: 'gray'},
  sendStatus: {background: blue[400]},
  failedStatus: {background: red[400]}
}

export const getClassByStatus = status => {
  const classes = {
    'DONE': 'doneStatus',
    'PENDING': 'pendingStatus',
    'SEND_TO_ABACUS': 'sendStatus',
    'TO_BE_PROCESSED_BY_ABACUS_ADAPTER': 'pendingStatus',
    'PROCESSING_BY_ADAPTER': 'pendingStatus',
    'FAILED': 'failedStatus'
  };
  return classes[status] || '';
};