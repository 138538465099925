import {t} from '../common/translationUtils';

export const debitCreditOptions = [
  {id: 'DEBIT', name: t('reasonCode.label.debit')},
  {id: 'CREDIT', name: t('reasonCode.label.credit')}
];

export const typesOptions = [
  {id: 'EMPTY', name: ' '},
  {id: 'SALE', name: 'Sale subcategories'},
  {id: 'PAY', name: 'Pay in / Pay out'},
  {id: 'CARD', name: 'Credit cards / Cash'}
];
