import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {routes} from '../../routes';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import SectionTitle from '../../common/components/layout/SectionTitle';
import PeasLoading from '../../common/components/forms/PeasLoading';
import PeasAlert from '../../common/components/forms/PeasAlert';
import PeasSelect from '../../common/components/forms/PeasSelect';
import * as actions from './actions';
import {getEmptyActiveResource, getFormButtonsObj} from '../../common/formUtils';
import formStyles from '../../common/styles/formStyles';
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import PeasDatePicker from "../../common/components/forms/PeasDatePicker";
import {getResourceToSave} from './selectors';

const BookingVoucherForm = props => {
  const [resource, setResource] = useState(getEmptyActiveResource(props));
  useEffect(init, []);
  useEffect(fillForm, [props.reasonCode]);
  useEffect(loadAll, []);

  function init() {
    const {id} = props.match.params;
    props.actions.loadResources(id);
  };

  function fillForm() {
    setResource(props.reasonCode ? props.reasonCode : getEmptyActiveResource(props));
  };

  function loadAll() {
    props.actions.loadAll();
  }

  const handleClose = withSuccess => {
    const route = withSuccess === true ?
      `${routes.interfaceServices.base}?success=true` :
      routes.interfaceServices.base;
    props.history.push(route);
  };

  const handleChange = field => value => {
    console.log('field ' + field);
    setResource({...resource, [field]: value});
  };

  const handleSelectChange = field => event => {
    const {value} = event.target;
    setResource({...resource, [field]: value});
  };
  
  const handleSubmit = () => {
    props.actions.save(getResourceToSave(resource), props.match.params.id, () => {
      handleClose(true);
    });
  };

  const {classes, loading, errors, data} = props;
  const title = 'Booking voucher';
  let oCustomers = [];
  let oSites = [];
  if (data !== undefined) {
    oCustomers = data.customers;
    oSites = data.sites;
    if (resource.customerId) {
      oSites = oSites.filter(x => x.customer.id === resource.customerId);
    }
  }
  const disabledPrint = resource.customerId === undefined || resource.siteId === undefined;
  const buttons = getFormButtonsObj({handleClose, handlePrint: handleSubmit, disabledPrint});
  return (loading ? <PeasLoading/> :
    <div>
      <SectionTitle label={title} buttons={buttons}/>
      <form className={classes.wideForm}>
        <FormGroup>
          <FormControl>
            <PeasAlert
              multiple
              variant="error"
              open={errors !== null && errors.length > 0}
              onClose={props.actions.resetError}
              messages={errors}/>
          </FormControl>
        </FormGroup>
        <Grid container spacing={24}>
          <Grid item xs={12} md={4}>
            <FormGroup>
              <FormControl>
                <PeasSelect
                    value={resource.customerId || ''}
                    onChange={handleSelectChange('customerId')}
                    label={'Customer'}
                    options={oCustomers}
                    name="customerId"/>
              </FormControl>
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormGroup>
              <FormControl>
                <PeasSelect
                  value={resource.siteId || ''}
                  onChange={handleSelectChange('siteId')}
                  label={'Site'}
                  options={oSites}
                  name="siteId"/>
              </FormControl>
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container spacing={24}>
          <Grid item xs={12} md={4}>
            <FormGroup>
              <FormControl>
                <PeasDatePicker
                  label={'From date'}
                  value={resource.businessDate !== undefined ? String(resource.businessDate) : String(new Date())}
                  onChange={handleChange('businessDate')}/>
              </FormControl>
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormGroup>
              <FormControl>
                <PeasDatePicker
                  label={'To date'}
                  value={resource.businessDateTo !== undefined ? String(resource.businessDateTo) : String(new Date())}
                  onChange={handleChange('businessDateTo')}/>
              </FormControl>
            </FormGroup>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

function mapStateToProps(state) {
  const {loading, reasonCode, errors, data, customers} = state.manualImportForm;
  return {
    loading, reasonCode, errors, data, customers
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(formStyles)(BookingVoucherForm));