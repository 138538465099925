import React from 'react';
import {t} from '../../common/translationUtils';
import {Tooltip} from '@material-ui/core';

const ItemStatusIndicators = props => {
  const {classes, operation} = props;
  const {completedCount, pendingCount, sendCount, failedCount} = operation;

  return [
    completedCount > 0 ? <Tooltip title={t('operations.completed')} key="completed">
      <span className={`${classes.itemStatus} ${classes.doneStatus}`}>
        {completedCount}
      </span>
    </Tooltip> : null,
    pendingCount > 0 ? <Tooltip title={t('operations.pending')} key="pending">
      <span className={`${classes.itemStatus} ${classes.pendingStatus}`}>
        {pendingCount}
      </span>
    </Tooltip> : null,
    sendCount > 0 ? <Tooltip title={t('operations.send')} key="send">
      <span className={`${classes.itemStatus} ${classes.sendStatus}`}>
        {sendCount}
      </span>
    </Tooltip> : null,
    failedCount > 0 ? <Tooltip title={t('operations.failed')} key="failed">
      <span className={`${classes.itemStatus} ${classes.failedStatus}`}>
        {failedCount}
      </span>
    </Tooltip> : null
  ];
};

export default ItemStatusIndicators;