import * as actionNames from './actionNames';
import axios from 'axios';
import * as serverUtils from '../../common/serverUtils';
import {USERS_ENDPOINT, CUSTOMERS_ENDPOINT} from '../../endpoints';
import {validate} from './validations';

const EMPTY_PASSWORD = "*****";

export const loadResources = id => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    try {
      const customerResponse = await axios.get(CUSTOMERS_ENDPOINT);
      const userResponse = id ? await axios.get(`${USERS_ENDPOINT}/${id}`) : null;
      dispatch({
        type: actionNames.LOADED_RESOURCES,
        customers: serverUtils.getArrayFromRequest(customerResponse, 'customerResourceList'),
        user: userResponse ? userResponse.data : null
      });
    } catch (error) {
      serverUtils.handleError(error, dispatch);
    };
  };
};

async function changePassword (id, newPassword) {
  return await axios.post(`${USERS_ENDPOINT}/${id}/password`, {
    newPassword,
    newPassword2: newPassword
  });
};

export const save = (resource, id, redirectCallback) => {
  const route = id ? `${USERS_ENDPOINT}/${id}` : USERS_ENDPOINT;
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    const {valid, errors} = validate(resource);
    if (!valid) {
      return dispatch({type: actionNames.ERROR, errors});
    }
    try {
      resource.password = resource.password === EMPTY_PASSWORD ? null : resource.password;
      if (id && resource.password) {
        changePassword(resource.id, resource.password);
      }
      await axios.post(route, resource);
      redirectCallback();
      dispatch({type: actionNames.SAVED});
    } catch (error) {
      const {response} = error;
      const message = response ? response.data.message : error.message;
      dispatch({
        type: actionNames.ERROR,
        errors: [message]
      });
    };
  };
};

export const resetError = () => {
  return dispatch => {
    dispatch({type: actionNames.RESET_ERROR});
  };
};
