import * as loginUtils from '../common/loginUtils';
import * as appActionNames from '../app/actionNames';

export const getArrayFromRequest = (response, field) => {
  const {_embedded} = response.data;
  return _embedded ? _embedded[field] : [];
};

export const handleError = (error, dispatch) => {
  const status = error.response ? error.response.status : error.status;
  const message = error.response ? error.response.statusText : 'Unexpected error has occured.';
  if (status === 401) {
    loginUtils.forgetUser();
    window.location.reload();
  } else {
    if(dispatch) {
      dispatch({type: appActionNames.APP_ERROR, error: message});
      setTimeout(() => {
        dispatch({type: appActionNames.APP_RESET_ERROR});
      }, 5000);
    }
    console.error(error);
  }
};
