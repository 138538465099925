export const setUrlParam = history => (name, value) => {
  const searchParams = new URLSearchParams(history.location.search);
  const currentValue = searchParams.get(name);
  if (currentValue !== value.toString()) {
    searchParams.set(name, value);
    history.push({
      search: searchParams.toString()
    });
  }
};

export const getUrlParam = history => name => {
  const searchParams = new URLSearchParams(history.location.search);
  return searchParams.get(name);
};
