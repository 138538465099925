import * as actionNames from './actionNames';

const initialState = {
  loading: false,
  synchronized: false,
  reports: [],
  customers: [],
  nameFilter: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case actionNames.LOADING:
    return {
      ...state,
      loading: true,
      synchronized: false
    };
  case actionNames.LOADED:
    return {
      ...state,
      loading: false,
      reports: action.reports,
      customers: action.customers
    };
  case actionNames.REPORT_LOADED:
    return {
      ...state,
      loading: false
    };
  case actionNames.SYNCHRONIZED:
    return {
      ...state,
      loading: false,
      nameFilter: null,
      synchronized: true
    };
  case actionNames.CHANGE_NAME_FILTER:
    return {
      ...state,
      nameFilter: action.nameFilter
    };
  default:
    return state
  }
};
