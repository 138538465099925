import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import PeasSelect from './PeasSelect';
import {LANGUAGES} from '../../langUtils';

const styles = {
  select: {
    marginTop: '-5px'
  }
};

const PeasTranslatedTextField = props => {
  const {classes} = props;
  const [activeLanguage, setActiveLanguage] = useState(LANGUAGES[0]);

  const getLanguageValue = () => {
    const {id} = activeLanguage;
    return props.value && props.value[id] ? props.value[id] : '';
  };

  const setLanguageObject = event => {
    const {value} = event.target;
    props.onChange({
      ...props.value,
      [activeLanguage.id]: value
    });
  };
  
  const handleLanguageChange = event => {
    setActiveLanguage(LANGUAGES.find(language => language.id === event.target.value));
  };

  return (
    <Grid container spacing={24}>
      <Grid item xs={10}>
        <TextField
          margin={props.type === 'table' ? undefined : 'normal'}
          fullWidth={props.fullWidth !== undefined ? props.fullWidth : true}
          {...props}
          value={getLanguageValue()}
          onChange={setLanguageObject}
          />
      </Grid>
      <Grid item xs={2} className={classes.select}>
        <PeasSelect
          value={activeLanguage.id || ''}
          onChange={handleLanguageChange}
          options={LANGUAGES.map(lang => lang.id)}
          name="language"/>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(PeasTranslatedTextField);
