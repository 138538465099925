import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, DatePicker} from 'material-ui-pickers';

const PeasDatePicker = props => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <DatePicker
      required={props.required}
      margin="normal"
      fullWidth
      className={props.className}
      format="dd.MM.yyyy"
      label={props.label}
      value={props.value}
      minDate={props.minDate}
      onChange={props.onChange}
      disabled={props.disabled}/>
  </MuiPickersUtilsProvider>
);

export default PeasDatePicker;