import {createValidation, validateFields} from '../../common/validationsUtils';

const fields = {
  'name': createValidation('common.name', 'text', true),
  'debitCredit': createValidation('reasonCode.label.debitCredit', 'enum', true),
  'accountNo': createValidation('reasonCode.label.accountNo', 'number', true),
  'costCentre': createValidation('reasonCode.label.costCentre', 'number', true)
}

export const validate = resource => {
  return validateFields(fields, resource);
};