import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {routes} from '../routes';
import Edit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import * as listUtils from '../common/listUtils';
import tableStyles from '../common/styles/tableStyles';

const CustomersTableBody = props => {

  const redirectToComments = customerId => () => {
    props.history.push(`${routes.comments.base}/${customerId}`);
  }

  const {reportsMode, classes, customers, routeConfig} = props;
  return customers.map(customer => (
    <TableRow
      key={customer.id}
      hover={reportsMode}
      onClick={reportsMode ? redirectToComments(customer.id) : undefined}
      className={reportsMode ? classes.clickableRow : null}>
      <TableCell component="th" scope="row" width="40px" align="right">
        {customer.number}
      </TableCell>
      <TableCell>{customer.name}</TableCell>
      <TableCell className={classes.tableActions}>
        {!reportsMode &&
          <IconButton aria-label="edit" onClick={listUtils.handleEdit(customer.id, routeConfig, props)}>
            <Edit fontSize="small"/>
          </IconButton>}
      </TableCell>
    </TableRow>
  ));
};

export default withStyles(tableStyles)(CustomersTableBody);