import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import PrivateRoute from './common/components/PrivateRoute';
import {getUser, getRolesAsArray, roles} from './common/loginUtils';

import Login from './login/Login';
import ChangePassword from './changePassword/ChangePassword';
import AdminDashboard from './adminDashboard/AdminDashboard';
import Users from './users/Users';
import UserForm from './users/form/UserForm';
import Customers from './customers/Customers';
import CustomerForm from './customers/form/CustomerForm';
import Comments from './comments/Comments';
import CommentForm from './comments/form/CommentForm';
import Subcategories from './subcategories/Subcategories';
import SubcategoryForm from './subcategories/form/SubcategoryForm';
import Categories from './categories/Categories';
import CategoryForm from './categories/form/CategoryForm';
import Sites from './sites/Sites';
import SiteForm from './sites/form/SiteForm';
import ReasonCodes from './reasonCodes/ReasonCodes';
import ReasonCodeForm from './reasonCodes/form/ReasonCodeForm';
import ImportPOSData from './importPOSData/ImportPOSData';
import ImportPOSItems from './importPOSData/items/ImportPOSItems';
import ImportPOSHistory from './importPOSData/history/ImportPOSHistory';
import ImportCreditCardData from './importCreditCardData/ImportCreditCardData';
import ImportCreditCardItems from './importCreditCardData/items/ImportCreditCardItems';
import ImportCreditCardHistory from './importCreditCardData/history/ImportCreditCardHistory';
import ImportKrediData from './importKrediData/ImportKrediData';
import ImportKrediItems from './importKrediData/items/ImportKrediItems';
import ImportKrediHistory from './importKrediData/history/ImportKrediHistory';
import MonthlyReports from './monthlyReports/MonthlyReports';
import Products from "./products/Products";
import ProductForm from "./products/form/ProductForm";
import Maintenance from "./maintenance/Maintenance";
import ManualImports from "./manualImport/ManualImports";
import ManualImportForm from "./manualImport/form/ManualImportForm";
import InterfaceServicesDashboard from './interfaceServicesDashboard/InterfaceServicesDashboard';
import BookingVoucherForm from "./bookingVoucher/form/BookingVoucherForm";

export const routes = {
  login: {
    base: "/login"
  },
  changePassword: {
    base: "/change_password"
  },
  reports: {
    base: "/reports"
  },
  adminDashboard: {
    base: "/admin_dashboard"
  },
  monthlyReports: {
    base: "/monthly_reports"
  },
  interfaceServices: {
    base: "/interface_services"
  },
  users: {
    base: "/users",
    form: "/user"
  },
  customers: {
    base: "/customers",
    form: "/customer"
  },
  comments: {
    base: "/comments",
    form: "/comment"
  },
  sites: {
    base: "/sites",
    form: "/site"
  },
  subcategories: {
    base: "/subcategories",
    form: "/subcategory"
  },
  products: {
    base: "/products",
    form: "/product"
  },
  categories: {
    base: "/categories",
    form: "/category"
  },
  manualImports: {
    base: "/manual_imports",
    form: "/manual_import"
  },
  bookingVouchers: {
    base: "/booking_vouchers",
    form: "/booking_voucher"
  },
  reasonCodes: {
    base: "/reason_codes",
    form: "/reason_code"
  },
  importPOSData: {
    base: "/import_pos_data",
    items: requestId => `/import_pos_data/${requestId}`,
    history: (requestId, itemId) => `/import_pos_data/${requestId}/item/${itemId}`
  },
  importCreditCardData: {
    base: "/import_credit_card_data",
    items: requestId => `/import_credit_card_data/${requestId}`,
    history: (requestId, itemId) => `/import_credit_card_data/${requestId}/item/${itemId}`
  },
  maintenance: {
    base: "/maintenance"
  },
  importKrediData: {
    base: "/import_kredi_data",
    items: requestId => `/import_kredi_data/${requestId}`,
    history: (requestId, itemId) => `/import_kredi_data/${requestId}/item/${itemId}`
  }
};

export const routing = (
  <div>
    <Route exact path="/" render={() => (
      getUser() ? (
        <Redirect to={routes.adminDashboard.base}/>
      ) : (
        <Redirect to={routes.login.base}/>
      )
    )}/>
    <Route path={routes.login.base} component={Login}/>
    <PrivateRoute path={routes.changePassword.base} component={ChangePassword} roles={getRolesAsArray()}/>
    <PrivateRoute exact path={routes.adminDashboard.base} component={AdminDashboard} roles={[roles.ADMIN]} />
    <PrivateRoute
      exact path={routes.reports.base}
      component={props => <Customers {...props} reportsMode/>}
      roles={[roles.USER, roles.ADMIN]}/>
    <PrivateRoute exact path={routes.users.base} component={Users} roles={[roles.ADMIN]}/>
    <PrivateRoute path={`${routes.users.form}/:id?`} component={UserForm} roles={[roles.ADMIN]}/>
    <PrivateRoute exact path={routes.customers.base} component={Customers} roles={[roles.ADMIN]}/>
    <PrivateRoute path={`${routes.customers.form}/:id?`} component={CustomerForm} roles={[roles.ADMIN]}/>
    <PrivateRoute exact path={routes.sites.base} component={Sites} roles={[roles.ADMIN]}/>
    <PrivateRoute path={`${routes.sites.form}/:id?`} component={SiteForm} roles={[roles.ADMIN]}/>
    <PrivateRoute exact path={routes.subcategories.base} component={Subcategories} roles={[roles.ADMIN]}/>
    <PrivateRoute path={`${routes.subcategories.form}/:id?`} component={SubcategoryForm} roles={[roles.ADMIN]}/>
    <PrivateRoute exact path={routes.products.base} component={Products} roles={[roles.ADMIN]}/>
    <PrivateRoute path={`${routes.products.form}/:id?`} component={ProductForm} roles={[roles.ADMIN]}/>
    <PrivateRoute exact path={routes.categories.base} component={Categories} roles={[roles.ADMIN]}/>
    <PrivateRoute path={`${routes.categories.form}/:id?`} component={CategoryForm} roles={[roles.ADMIN]}/>
    <PrivateRoute exact path={routes.manualImports.base} component={ManualImports} roles={[roles.REPORTS]}/>
    <PrivateRoute path={`${routes.manualImports.form}/:id?`} component={ManualImportForm} roles={[roles.REPORTS]}/>
    <PrivateRoute exact path={routes.bookingVouchers.base} component={BookingVoucherForm} roles={[roles.REPORTS]}/>
    <PrivateRoute exact path={routes.reasonCodes.base} component={ReasonCodes} roles={[roles.ADMIN]}/>
    <PrivateRoute path={`${routes.reasonCodes.form}/:id?`} component={ReasonCodeForm} roles={[roles.ADMIN]}/>
    <PrivateRoute path={`${routes.comments.base}/:customerId?`} component={Comments} roles={[roles.USER, roles.ADMIN]}/>
    <PrivateRoute path={`${routes.comments.form}/:customerId/:commentId?`} component={CommentForm} roles={[roles.USER, roles.ADMIN]}/>
    <PrivateRoute exact path={routes.maintenance.base} component={Maintenance} roles={[roles.ADMIN]}/>
    <PrivateRoute exact path={routes.importPOSData.base} component={ImportPOSData} roles={[roles.ADMIN]}/>
    <PrivateRoute exact path={`${routes.importPOSData.base}/:requestId`} component={ImportPOSItems} roles={[roles.ADMIN]}/>
    <PrivateRoute exact path={`${routes.importPOSData.base}/:requestId/item/:itemId`} component={ImportPOSHistory} roles={[roles.ADMIN]}/>
    <PrivateRoute exact path={routes.importCreditCardData.base} component={ImportCreditCardData} roles={[roles.ADMIN]}/>
    <PrivateRoute exact path={`${routes.importCreditCardData.base}/:requestId`} component={ImportCreditCardItems} roles={[roles.ADMIN]}/>
    <PrivateRoute exact path={`${routes.importCreditCardData.base}/:requestId/item/:itemId`} component={ImportCreditCardHistory} roles={[roles.ADMIN]}/>
    <PrivateRoute exact path={routes.importKrediData.base} component={ImportKrediData} roles={[roles.ADMIN]}/>
    <PrivateRoute exact path={`${routes.importKrediData.base}/:requestId`} component={ImportKrediItems} roles={[roles.ADMIN]}/>
    <PrivateRoute exact path={`${routes.importKrediData.base}/:requestId/item/:itemId`} component={ImportKrediHistory} roles={[roles.ADMIN]}/>
    <PrivateRoute path={routes.monthlyReports.base} component={MonthlyReports} roles={[roles.REPORTS]}/>
    <PrivateRoute path={routes.interfaceServices.base} component={InterfaceServicesDashboard} roles={[roles.REPORTS]}/>
  </div>
);
