import * as actionNames from './actionNames';

const initialState = {
  error: null,
  roles: null,
  lang: 'en',
  translations: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case actionNames.ROLES_LOADED:
    return {...state, roles: action.roles};
  case actionNames.LANGUAGE_CHANGED:
    return {...state, lang: action.lang};
  case actionNames.TRANSLATIONS_LOADED:
    return {...state, translations: action.translations};
  case actionNames.APP_ERROR:
    return {...state, error: action.error};
  case actionNames.APP_RESET_ERROR:
    return {...state, error: null};
  default:
    return state
  }
};
