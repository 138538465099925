/*  HELPERS FOR GENERIC FORMS */
import React from 'react';
import {t} from '../common/translationUtils';
import PeasButton from './components/forms/PeasButton';

export const formButton = (handler, color, label, disable) => {
  return <PeasButton
  disabled={disable}
  color={color}
  label={label}
  onClick={handler}/>;
}

// Getting generic form buttons
export const getFormButtons = (handleClose, handleDelete, handleSubmit, handleExecute, disabledExecute) => {
  const buttons = [];
  if (handleClose) {
    buttons.push(formButton(handleClose, null, t('common.button.cancel')));
  }
  if (handleDelete) {
    buttons.push(formButton(handleDelete, 'secondary', t('common.button.delete')));
  }
  if (handleSubmit) {
    buttons.push(formButton(handleSubmit, 'primary', t('common.button.save')));
  }
  if (handleExecute) {
    buttons.push(formButton(handleExecute, 'secondary', t('common.button.execute'), disabledExecute));
  }
  return buttons;
};

export const getFormButtonsObj = ({handleClose, handleDelete, handleSubmit, handleExecute, handlePrint, disabledPrint, disabledExecute}) => {
  const buttons = [];
  if (handleClose) {
    buttons.push(formButton(handleClose, null, t('common.button.cancel')));
  }
  if (handleDelete) {
    buttons.push(formButton(handleDelete, 'secondary', t('common.button.delete')));
  }
  if (handleSubmit) {
    buttons.push(formButton(handleSubmit, 'primary', t('common.button.save')));
  }
  if (handlePrint) {
    buttons.push(formButton(handlePrint, 'primary', t('common.button.print'), disabledPrint));
  }
  if (handleExecute) {
    buttons.push(formButton(handleExecute, 'secondary', t('common.button.execute'), disabledExecute));
  }
  return buttons;
};

export const getEmptyActiveResource = (props, type = '') => ({
  active: props.match.params.id ? undefined : true,
  businessDate: String(new Date().toISOString()),
  businessDateTo: String(new Date().toISOString()),
  ...(type === 'WITH_TRANSLATIONS' ? getEmptyTranslations() : {})
});

export const getEmptyTranslations = () => {
  return {nameTranslations: {en: '', de: '', fr: '', it: ''}};
};
