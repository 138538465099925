import {createValidation, validateFields} from '../../common/validationsUtils';
import {t} from '../../common/translationUtils';

const errorMessages = {
  ROLE_ERROR: 'user.error.emptyRole'
};

const fields = {
  'firstname': createValidation('user.label.firstname', 'text', true),
  'lastname': createValidation('user.label.lastname', 'text', true),
  'username': createValidation('user.label.loginname', 'text', true),
  'email': createValidation('common.email', 'email', true),
  'password': createValidation('common.password', 'password', true)
}

export const validate = resource => {
  const {roles} = resource;
  let {errors} = validateFields(fields, resource);
  if(!roles || roles.length === 0) {
    errors.push(t(errorMessages.ROLE_ERROR));
  }
  return {valid: errors.length === 0, errors};
};