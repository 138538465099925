import * as actionNames from './actionNames';
import axios from 'axios';
import {handleError} from '../../common/serverUtils';
import {USERS_ENDPOINT, MANUAL_IMPORTS_ENDPOINT, CUSTOMERS_ENDPOINT, BOOKING_VOUCHERS_ENDPOINT} from '../../endpoints';
import {validate} from './validations';
import {getUser, roles} from '../../common/loginUtils';

export const loadAll = () => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING_MAIN});
    try {
      const user = getUser();
      const customersLink = !user.roles.includes(roles.ADMIN)
        ? `${USERS_ENDPOINT}/${user.userId}/customers`
        : CUSTOMERS_ENDPOINT;
      const customersResponse = await axios.get(customersLink);
      const importResponse = await axios.get(`${MANUAL_IMPORTS_ENDPOINT}/data`);

      const {_embedded: customersEmbedded} = customersResponse.data;
      dispatch({
        type: actionNames.LOADED_MAIN_RESOURCES,
        data: importResponse ? importResponse.data : null,
        customers: customersEmbedded
          ? customersEmbedded.customerResourceList
          : []
      });
    } catch (error) {
      handleError(error, dispatch);
    };
  };
};

export const loadResources = id => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    try {
      const reasonCodeResponse = id ? await axios.get(`${MANUAL_IMPORTS_ENDPOINT}/${id}`) : null;
      dispatch({
        type: actionNames.LOADED_RESOURCES,
        reasonCode: reasonCodeResponse ? reasonCodeResponse.data : null
      });
    } catch (error) {
      handleError(error, dispatch);
    };
  };
};

export const save = (resource, id, redirectCallback) => {
  const route = BOOKING_VOUCHERS_ENDPOINT + '/get' +
      '?businessDate=' + (typeof resource.businessDate === 'object' ? resource.businessDate.toISOString() : resource.businessDate) +
      '&businessDateTo=' + (typeof resource.businessDateTo === 'object' ? resource.businessDateTo.toISOString() : resource.businessDateTo) +
      '&customerId=' + resource.customerId +
      '&siteId=' + resource.siteId;
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    const {valid, errors} = validate(resource);
    if (!valid) {
      return dispatch({type: actionNames.ERROR, errors});
    }
    try {
      // await axios.post(route, resource);
      window.open(route, 'pdf.pdf');
      // redirectCallback();
      dispatch({type: actionNames.SAVED});
    } catch (error) {
      dispatch({
        type: actionNames.ERROR,
        errors: [error.message]
      });
    };
  };
};

export const process = (id, redirectCallback) => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    try {
      await axios.patch(`${MANUAL_IMPORTS_ENDPOINT}/${id}`);
      redirectCallback();
      dispatch({type: actionNames.REMOVED});
    } catch (error) {
      dispatch({
        type: actionNames.ERROR,
        errors: [error.message]
      });
    };
  };
};

export const remove = (id, redirectCallback) => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    try {
      await axios.delete(`${MANUAL_IMPORTS_ENDPOINT}/${id}`);
      redirectCallback();
      dispatch({type: actionNames.REMOVED});
    } catch (error) {
      dispatch({
        type: actionNames.ERROR,
        errors: [error.message]
      });
    };
  };
};

export const resetError = () => dispatch => {
  dispatch({type: actionNames.RESET_ERROR});
};
