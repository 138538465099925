import {createValidation, validateFields} from '../../common/validationsUtils';
import {t} from '../../common/translationUtils';

const fields = {
  'number': createValidation('common.number', 'number', true),
  'nameTranslations': createValidation('common.name', 'translations', true),
  'category': createValidation('common.category', 'enum', true)
}

export const validate = resource => {
  const {errors} = validateFields(fields, resource);
  const accountFields = ['taxRate', 'taxCode', 'accountNo', 'costAccount', 'stockAccount'];
  const isAccountInvalid = resource.accounts.some(account => {
    return accountFields.some(field => isNaN(account[field]));
  });
  if (isAccountInvalid) {
    errors.push(t('subcategory.error.accounts'));
  }
  return {valid: errors.length === 0, errors};
};