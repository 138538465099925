import React, {useState, useEffect} from 'react';
import {t} from '../../common/translationUtils';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {getFormButtons} from  '../../common/formUtils';
import tableStyles from '../../common/styles/tableStyles';
import SubcategoryMargeRow from './SubcategoryMargeRow';
import {sortByDate} from '../../common/sortUtils';
import {getMargeValueByField, findSiteSubcategoryById} from './selectors';

const SubcategoryMargesList = props => {
  const [marges, setMarges] = useState([]);
  const [newMarge, setNewMarge] = useState({});
  const {subcategory, resource, classes, open} = props;

  /* 
    Setting marges list for edited subcategory
    always when popup is displayed.
  */
  useEffect(() => {
    if (!resource || !resource.subcategories) {
      return;
    }
    const siteSubcategory = findSiteSubcategoryById(subcategory.id, resource.subcategories);
    setMarges(siteSubcategory ? siteSubcategory.marges : []);
  }, [props.open]);

  const handleNewMargeChange = field => result => {
    setNewMarge({...newMarge, [field]: getMargeValueByField(field, result)});
  };

  const handleExistingMargeChange = index => field => result => {
    marges[index] = {...marges[index], [field]: getMargeValueByField(field, result)};
    setMarges([...marges]);
  };

  const handleRemoveMarge = index => () => {
    const updatedMarges = [...marges];
    updatedMarges.splice(index, 1);
    setMarges(updatedMarges);
  };

  const handleAddMarge = () => {
    setMarges([...marges, newMarge]);
    setNewMarge({});
  };

  const handleClose = () => {
    setMarges([]);
    setNewMarge({});
    props.handleClose();
  };
  
  const handleSubmit = () => {
    props.handleSubmit(marges);
    handleClose();
  };

  return (
    <Dialog
      open={Boolean(open)}
      aria-labelledby="site-marges-list">
      {subcategory && subcategory.name ? <DialogTitle id="site-marges-list">
        {t('site.subcategories.marges', {subcategory: subcategory.name})}
      </DialogTitle> : null}
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="100px" className={classes.tableHeader}>{t('common.validFrom')}</TableCell>
              <TableCell className={classes.tableHeader}>{t('subcategory.label.marge')}</TableCell>
              <TableCell className={classes.tableHeader}/>
            </TableRow>
          </TableHead>
          <TableBody>
            {marges ? marges.sort(sortByDate('validFrom')).reverse().map((marge, index) => (
              <SubcategoryMargeRow
                type="EDIT"
                key={`${index}-${marge.id}`}
                marge={marge}
                handleChange={handleExistingMargeChange(index)}
                handleRemove={handleRemoveMarge(index)}
                classes={classes}/>
            )) : null}
            <SubcategoryMargeRow
              type="ADD"
              key="add-row"
              marge={newMarge}
              handleChange={handleNewMargeChange}
              handleAdd={handleAddMarge}
              classes={classes}/>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        {getFormButtons(handleClose, null, handleSubmit).map((button, index) => (
          <span key={index}>{button}</span>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(tableStyles)(SubcategoryMargesList);