import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {layoutStyles} from './styles';

const SectionTitle = props => (
  <Grid container spacing={24}>
    <Grid item sm={6}>
      <Typography variant="h5">
        {props.label}
      </Typography>
      {props.subtitle ? <Typography variant="h5" className={props.classes.sectionSubtitle}>
        {props.subtitle}
      </Typography> : null}
    </Grid>
    <Grid item sm={6} className={props.classes.sectionButtons}>
      {props.buttons && props.buttons.map((button, index) => (
        <span key={index} className={props.classes.sectionButton}>{button}</span>
      ))}
    </Grid>
  </Grid>
);

export default withStyles(layoutStyles)(SectionTitle);