import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';

const styles = {
  tableSelect: {
    marginTop: '0px !important'
  }
};

const PeasSelect = props => {
  const tableMode = props.type === 'table';
  return (
    <FormControl fullWidth margin={tableMode ? undefined : 'normal'}>
      <InputLabel htmlFor={`${props.name}-simple`} required={props.required}>
        {props.label}
      </InputLabel>
      <Select
        className={tableMode ? props.classes.tableSelect : null}
        value={props.value}
        onChange={props.onChange}
        inputProps={{
          name: props.name,
          id: `${props.name}-simple`
        }}>
        {props.options ? props.options.map(option => {
          const label = option.name || option;
          const value = option.id || option;
          return (<MenuItem key={value} value={value}>
            <ListItemText value={value} primary={label} />
          </MenuItem>);
        }) : null}
      </Select>
    </FormControl>
  );
};

export default withStyles(styles)(PeasSelect);