import {t} from './translationUtils';
import {LANGUAGES} from './langUtils';

export const errorMessages = {
  EMPTY_ERROR: 'common.error.empty',
  NUMBER_ERROR: 'common.error.number',
  ALPHANUMERIC_ERROR: 'common.error.alphanumeric',
  EMAIL_ERROR: 'common.error.email'
};

const alphanumericRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/i;
const emailRegex = /\S+@\S+\.\S+/;

const isTranslationsEmpty = translations => {
  return !LANGUAGES.some(language => {
    return translations[language.id] !== '';
  });
};

export const createValidation = (label, type, required) => {
  return {label, type, required};
};

export const validateFields = (fields, resource) => {
  let errors = [];
  for(const [field, value] of Object.entries(fields)) {
    if(!resource[field] && resource[field] !== 0) {
      const field = t(value.label);
      errors.push(t(errorMessages.EMPTY_ERROR, {field}));
    }
    if(value.type === 'translations' && isTranslationsEmpty(resource[field])) {
      const field = t(value.label);
      errors.push(t(errorMessages.EMPTY_ERROR, {field}));
    }
    if(value.type === 'number' && resource[field] !== undefined && isNaN(resource[field])) {
      const field = t(value.label);
      errors.push(t(errorMessages.NUMBER_ERROR, {field}));
    }
    if(value.type === 'alphanumeric' && resource[field] && !alphanumericRegex.test(resource[field])) {
      const field = t(value.label);
      errors.push(t(errorMessages.ALPHANUMERIC_ERROR, {field}));
    }
    if(value.type === 'email' && resource[field] && !emailRegex.test(resource[field])) {
      errors.push(t(errorMessages.EMAIL_ERROR));
    }
  };
  return {valid: errors.length === 0, errors};
};