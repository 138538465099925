export default {
  tableContainer: {
    overflowX: 'auto',
    margin: '20px 0 0 0'
  },
  tableHeader: {
    borderBottom: '1px solid #ed891c'
  },
  tableActions: {
    textAlign: 'right'
  },
  tableNoEntries: {
    margin: '15px 0',
    textAlign: 'center'
  },
  clickableRow: {
    cursor: 'pointer'
  },
  cellButton: {
    fontSize: '0.6rem'
  },
  bottomButton: {
    float: 'right',
    marginTop: '30px'
  }
};