import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {t} from '../common/translationUtils';
import {withStyles} from '@material-ui/core/styles';
import {routes} from '../routes';
import Edit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PeasAlert from '../common/components/forms/PeasAlert';
import PeasLoading from '../common/components/forms/PeasLoading';
import SectionTitle from '../common/components/layout/SectionTitle';
import ActiveSelect from '../common/components/list/ActiveSelect';
import * as actions from './actions';
import * as listUtils from '../common/listUtils';
import {sortByNumber} from '../common/sortUtils';
import {debitCreditOptions} from './constants';
import tableStyles from '../common/styles/tableStyles';

const ReasonCodes = props => {
  const routeConfig = {
    backRoute: routes.adminDashboard.base,
    mainRoute: routes.reasonCodes
  };

  useEffect(() => {
    props.actions.loadReasonCodes(props.activeOption);
  }, []);

  const handleActiveOptionChange = event => {
    const {value} = event.target;
    props.actions.changeActiveOption(value);
    props.actions.loadReasonCodes(value);
  };

  const {loading, reasonCodes, classes, location} = props;
  const displayReasonCodes = reasonCodes && reasonCodes.length > 0;
  const isSuccess = new URLSearchParams(location.search).get('success');

  return (loading ? <PeasLoading/> :
    <div>
      <SectionTitle
        label={t('common.reasonCodes')}
        buttons={listUtils.getListButtons(routeConfig, props)}/>
      <PeasAlert
          variant="success"
          open={isSuccess}
          onClose={listUtils.removeSuccessMessage(routeConfig, props)}
          message={t('common.message.success')}/>
      <ActiveSelect
        value={props.activeOption}
        onChange={handleActiveOptionChange}/>
      <div className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>{t('common.name')}</TableCell>
              <TableCell className={classes.tableHeader}>{t('reasonCode.label.debitCredit')}</TableCell>
              <TableCell className={classes.tableHeader}>{t('reasonCode.label.accountNo')}</TableCell>
              <TableCell className={classes.tableHeader}>{t('reasonCode.label.costCentre')}</TableCell>
              <TableCell className={classes.tableHeader}>{t('reasonCode.label.code')}</TableCell>
              <TableCell className={classes.tableHeader}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayReasonCodes && reasonCodes.sort(sortByNumber('number')).map(reasonCode => (
              <TableRow key={reasonCode.id}>
                <TableCell component="th" scope="row">{reasonCode.name}</TableCell>
                <TableCell>
                  {debitCreditOptions.find(opt => opt.id === reasonCode.debitCredit).name}
                </TableCell>
                <TableCell>{reasonCode.accountNo}</TableCell>
                <TableCell>{reasonCode.costCentre}</TableCell>
                <TableCell>{reasonCode.code}</TableCell>
                <TableCell className={classes.tableActions}>
                  <IconButton aria-label="edit" onClick={listUtils.handleEdit(reasonCode.id, routeConfig, props)}>
                    <Edit fontSize="small"/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!displayReasonCodes && <Typography variant="body1" className={classes.tableNoEntries}>
          {t('reasonCodes.message.empty')}
        </Typography>}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const {loading, reasonCodes, activeOption} = state.reasonCodes;
  return {loading, reasonCodes, activeOption};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(tableStyles)(ReasonCodes));