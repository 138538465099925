import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import PeasLoading from '../common/components/forms/PeasLoading';
import PeasButton from '../common/components/forms/PeasButton';
import PeasTextField from '../common/components/forms/PeasTextField';
import PeasAlert from '../common/components/forms/PeasAlert';
import {t} from '../common/translationUtils';
import {getMainRole} from '../common/loginUtils';
import {roleDefaultRoutes} from '../common/routeUtils';
import * as actions from './actions';
import styles from './styles';



const Login = props => {
  const [resource, setResource] = useState({
    username: '',
    password: ''
  });

  const handleChange = field => event => {
    setResource({...resource, [field]: event.target.value});
  };

  const handleLogin = event => {
    event.preventDefault();
    props.actions.login(resource, user => {
      props.actions.getRoles();
      const route = roleDefaultRoutes[getMainRole(user.roles)];
      props.history.push(route);
    });
  };

  const {classes, login} = props;
  const {username, password} = resource;

  return (login.loggingIn ? <PeasLoading/> :
    <form className={classes.root} onSubmit={handleLogin}>
      <img src={process.env.PUBLIC_URL + "/shell_logo.svg"} className={classes.logo} alt="SHELL ERP"/>
      <PeasAlert
        variant="error"
        open={login.error !== null}
        onClose={props.actions.resetError}
        message={t('login.error.failed')}
      />
      <PeasTextField
        id="username"
        label={t('common.username')}
        value={username || ''}
        onChange={handleChange('username')}
      />
      <PeasTextField
        id="password"
        label={t('common.password')}
        type="password"
        value={password || ''}
        onChange={handleChange('password')}
      />
      <PeasButton
        label={t('login.submit')}
        type="submit"
        className={classes.loginButton}
        fullWidth
        disabled={!username || !password}/>
    </form>
  );
};

function mapStateToProps(state) {
  return {
    login: state.login
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Login));