import React, {useState} from 'react';
import {t} from '../../common/translationUtils';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Edit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import tableStyles from '../../common/styles/tableStyles';
import {getSiteSubcategoryMarge, findSiteSubcategoryById, sortByCategory} from './selectors';
import SubcategoryMargesList from './SubcategoryMargesList';

const SiteSubcategories = props => {
  const [editedSubcategory, setEditedSubcategory] = useState(false);
  const {resource, subcategories, categories, classes, visible} = props;

  const getSubcategoryCategory = id => {
    const subcategory = subcategories.find(s => s.id === id);
    const category = subcategory ? categories.find(c => c.id === Number(subcategory.category)) : null;
    return category ? category.name : '';
  };

  const prepareSiteSubcategoryList = () => {
    const siteSubcategories = subcategories.map(subcategory => {
      return {
        id: subcategory.id,
        name: subcategory.name,
        category: getSubcategoryCategory(subcategory.id),
        marge: getSiteSubcategoryMarge(resource, subcategory.id)
      }
    });
    return siteSubcategories.sort(sortByCategory);
  };

  const handleEdit = subcategory => () => {
    setEditedSubcategory(subcategory);
  };

  const handleAddSubcategoryMarges = marges => {
    const siteSubcategories = resource && resource.subcategories ? resource.subcategories : [];
    const siteSubcategory = findSiteSubcategoryById(editedSubcategory.id, siteSubcategories);
    if (siteSubcategory) {
      siteSubcategory.marges = [...marges];
    } else {
      const {name, id: subcategoryId} = editedSubcategory;
      siteSubcategories.push({subcategoryId, name, marges});
    }
    props.onChange(siteSubcategories);
  };

  return visible ? (<div className={classes.tableContainer}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width="200px" className={classes.tableHeader}>{t('common.name')}</TableCell>
          <TableCell width="200px" className={classes.tableHeader}>{t('common.category')}</TableCell>
          <TableCell className={classes.tableHeader}>{t('subcategory.label.marge')}</TableCell>
          <TableCell className={classes.tableHeader}/>
        </TableRow>
      </TableHead>
      <TableBody>
        {subcategories ? prepareSiteSubcategoryList(subcategories).map((subcategory, index) => (
          <TableRow key={`${index}-${subcategory.id}`}>
            <TableCell component="th" scope="row">{subcategory.name}</TableCell>
            <TableCell component="th" scope="row">{subcategory.category}</TableCell>
            <TableCell>{subcategory.marge}</TableCell>
            <TableCell className={classes.tableActions}>
              <IconButton aria-label="edit" onClick={handleEdit(subcategory)}>
                <Edit fontSize="small"/>
              </IconButton>
            </TableCell>
          </TableRow>
        )) : null}
      </TableBody>
    </Table>
    <SubcategoryMargesList
      open={editedSubcategory}
      subcategory={editedSubcategory}
      resource={resource}
      handleSubmit={handleAddSubcategoryMarges}
      handleClose={() => {setEditedSubcategory(false)}}/>
  </div>) : null;
};

export default withStyles(tableStyles)(SiteSubcategories);