export const getFilters = filters => {
  let filterQuery = '?';
  const nonEmptyFilters = filters.filter(f => f.length > 0);
  nonEmptyFilters.forEach((filter, index) => {
    const andPrefix = index > 0 ? '&' : '';
    filterQuery += `${andPrefix}${filter}`;
  });
  return filterQuery;
};

export const getFilter = (name, comparator, value) => {
  const isEmpty = value === null || value === undefined;
  return isEmpty ? '' : `filters=${name},${comparator},${value}`;
};

export const addFiltersToUrl = (url, filters) => {
  return `${url}${filters}`;
};

export const getEqFilter = (name, value) => {
  return getFilter(name, 'eq', value);
};

export const getLtFilter = (name, value) => {
  return getFilter(name, 'lt', value);
};

export const getGtFilter = (name, value) => {
  return getFilter(name, 'gt', value);
};

export const getInFilter = (name, value) => {
  return getFilter(name, 'in', value);
};

export const getLikeFilter = (name, value) => {
  const textValue = value === '' ? null : value;
  return getFilter(name, 'like', textValue);
};

export const getPageFilter = page => {
  return `page=${page}`;
};

export const getSizeFilter = size => {
  return `size=${size}`;
};

export const getSortFilter = (name, sort) => {
  return `sort=${name},${sort}`;
};