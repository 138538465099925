import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';

const styles = {
  root: {
    width: '100%',
    height: '100%',
    padding: '200px 0px',
    textAlign: 'center'
  }
};

const PeasLoading = props => (
  <div className={props.classes.root}>
    <CircularProgress/>
  </div>
);

export default withStyles(styles)(PeasLoading);