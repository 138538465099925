import {createValidation, validateFields} from '../../common/validationsUtils';

const fields = {
  'number': createValidation('common.no', 'number', true),
  'name': createValidation('common.name', 'text', true),
  'debitCredit': createValidation('product.label.debitCredit', 'enum', true),
  'accountNo': createValidation('product.label.accountNo', 'number', true),
  'costCentre': createValidation('product.label.costCentre', 'number', true)
}

export const validate = resource => {
  return validateFields(fields, resource);
};
