import * as actionNames from './actionNames';
import axios from 'axios';
import {handleError} from '../common/serverUtils';
import {SUBCATEGORIES_ENDPOINT, CATEGORIES_ENDPOINT} from '../endpoints';
import {getFilters, getEqFilter} from '../common/filterUtils';

const filters = active => [
  active === 'all' ? '' : getEqFilter('active', active)
];

export const loadSubcategories = activeOption => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    const filterQuery = getFilters(filters(activeOption));
    try {
      const response = await axios.get(`${SUBCATEGORIES_ENDPOINT}${filterQuery}`);
      const categoriesResponse = await axios.get(CATEGORIES_ENDPOINT);
      const {_embedded} = response.data;
      const {_embedded: categoriesEmbedded} = categoriesResponse.data;
      dispatch({
        type: actionNames.LOADED, 
        subcategories: _embedded ? _embedded.subcategoryResourceList : [],
        categories: categoriesEmbedded ? categoriesEmbedded.categoryResourceList : []
      });
    } catch (error) {
      handleError(error, dispatch);
    };
  };
};

export const changeActiveOption = activeOption => dispatch => {
  dispatch({type: actionNames.CHANGE_ACTIVE_OPTION, activeOption});
};
