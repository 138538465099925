import * as actionNames from './actionNames';
import axios from 'axios';
import {handleError, getArrayFromRequest} from '../../common/serverUtils';
import {OPERATIONS_CREDIT_CARD_ENDPOINT} from '../../endpoints';
import {getFilters, getPageFilter, getSizeFilter, addFiltersToUrl} from '../../common/filterUtils';
import {DEFAULT_PAGE_SIZE} from '../selectors';

export const loadHistory = (filters, page, params) => {
  return async dispatch => {
    dispatch({type: actionNames.LOADING});
    const filterQuery = getFilters([
      getPageFilter(page),
      getSizeFilter(DEFAULT_PAGE_SIZE)
    ]);

    try {
      const encodedId = encodeURIComponent(params.requestId);
      const link = `${OPERATIONS_CREDIT_CARD_ENDPOINT}/${encodedId}/items/${params.itemId}/history`;
      const response = await axios.get(addFiltersToUrl(link, filterQuery));
      dispatch({
        type: actionNames.LOADED, 
        itemHistory: getArrayFromRequest(response, 'auditCreditCardItemHistoryResourceList'),
        paginationConfig: response.data ? response.data.page : null
      });
    } catch (error) {
      handleError(error, dispatch);
    };
  };
};
