import * as actionNames from './actionNames';
import axios from 'axios';
import {TRANSLATIONS_ENDPOINT} from '../endpoints';

const getDefaultLanguage = () => {
  const rememberedLanguage = localStorage.getItem('language');
  if (rememberedLanguage) {
    return rememberedLanguage;
  }
  const acceptedLanguages = ['en', 'de', 'fr'];
  const browserLanguage = window.navigator.userLanguage || window.navigator.language;
  return acceptedLanguages.includes(browserLanguage.slice(0,2)) ? browserLanguage.slice(0,2) : 'en';
};

export const setLanguage = lang => dispatch => {
  if (lang) {
    localStorage.setItem('language', lang);
  } else {
    lang = getDefaultLanguage();
  }
  dispatch({type: actionNames.LANGUAGE_CHANGED, lang});
  getTranslations(lang)(dispatch);
};

export const getTranslations = lang => {
  const route = lang ? `${TRANSLATIONS_ENDPOINT}/${lang}` : TRANSLATIONS_ENDPOINT;
  return async dispatch => {
    try {
      const response = await axios.get(route);
      localStorage.setItem('translations', JSON.stringify(response.data));
      dispatch({
        type: actionNames.TRANSLATIONS_LOADED,
        translations: response.data
      });
    } catch (error) {};
  };
};