import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

const styles = {
  root: {
    width: '100%',
    textAlign: 'center',
    marginTop: '20px'
  },
  text: {
    display: 'inline-block',
    margin: '0 10px',
    verticalAlign: 'middle'
  }
};

const PeasYearStepper = props => (
  <div className={props.classes.root}>
    <IconButton aria-label="back" onClick={props.onClick(-1)}>
      <ArrowBack fontSize="small"/>
    </IconButton>
    <Typography variant="h5" className={props.classes.text}>
      {props.year}
    </Typography>
    <IconButton aria-label="next" onClick={props.onClick(1)}>
      <ArrowForward fontSize="small"/>
    </IconButton>
  </div>
);

export default withStyles(styles)(PeasYearStepper);